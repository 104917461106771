import React from 'react';

export const DropdownIcon = (props: any) => {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.20259 1.17511L2.20252 1.17504C2.06487 1.03121 1.87812 0.95 1.68291 0.95C1.4877 0.95 1.30095 1.03121 1.1633 1.17504L1.1631 1.17525C1.02635 1.31984 0.95 1.51492 0.95 1.71778C0.95 1.92065 1.02635 2.11573 1.1631 2.26032L1.1632 2.26043L5.52487 6.84483L5.52505 6.84502C5.65104 6.97603 5.82166 7.05 6 7.05C6.17834 7.05 6.34896 6.97603 6.47495 6.84502L6.47513 6.84483L10.8368 2.26043L10.8369 2.26032C10.9736 2.11573 11.05 1.92065 11.05 1.71779C11.05 1.51492 10.9736 1.31984 10.8369 1.17525L10.8367 1.17504C10.699 1.03121 10.5123 0.95 10.3171 0.95C10.1219 0.95 9.93513 1.03121 9.79748 1.17504L9.79741 1.17511L6 5.1593L2.20259 1.17511Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.1"
      />
    </svg>
  );
};
