import React from 'react';

export const RoundTickIcon = (props: any) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      stroke={'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="white" />
      <path
        d="M20 13L14.5 18.5L12 16"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
