// LanguageProvider.tsx
import React, { createContext, useContext, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { languageActions } from './store/reducers/language.store';

interface LanguageContextType {
  selectedLanguage: 'en' | 'ar';
  setSelectedLanguage: (language: 'en' | 'ar') => void;
}

const LanguageContext = createContext<LanguageContextType | undefined>(
  undefined
);

interface LanguageProviderProps {
  children: ReactNode;
}

export function LanguageProvider({ children }: LanguageProviderProps) {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector(
    (state: any) => state.language.selectedLanguage
  );

  const contextValue: LanguageContextType = {
    selectedLanguage,
    setSelectedLanguage: (language) =>
      dispatch(languageActions.setLanguage(language)),
  };

  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguage() {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
}
