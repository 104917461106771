import { SupportedFileTypes } from './../../api/models/upload';
import { UploadedFileInfo } from './../../models/upload';
import { Dict } from './../../models/interfaces';
import { StudentDocument, DocumentInfo } from './../../models/student-document';
import { Dispatch } from 'redux';
import { saveAs } from 'file-saver';

import api from '../../api';
import { toastr } from 'react-redux-toastr';
import { Payment, LumpsumPaymentDetails } from '../../models/payment';
import { PaymentResponse } from '../../api/models/payment';
import { types as commonTypes } from './common';
import { State } from '../interfaces';
import { isAllDocumentsUpdationCompleted } from '../../utils/student-document-utils';
import { Amounts } from '../../models/amounts';
import { AdditionalAddonResponse } from '../../api/models/additional-addons';
import { MyReferralResponse } from '../../api/models/myReferal';
import { ErrorResponse } from './error';
// import { toastr } from 'react-redux-toastr';

// action types
export const types = {
  // documents
  FETCH_DOCUMENTS_REQUEST: 'DASHBOARD/DOCUMENT/FETCH_DOCUMENTS_REQUEST',
  FETCH_DOCUMENTS_SUCCESS: 'DASHBOARD/DOCUMENT/FETCH_DOCUMENTS_SUCCESS',
  FETCH_DOCUMENTS_ERROR: 'DASHBOARD/DOCUMENT/FETCH_DOCUMENTS_ERROR',
  // skip
  SKIP_COURSE_STAGE_REQUEST: 'DASHBOARD/DOCUMENT/SKIP_COURSE_STAGE_REQUEST',
  SKIP_COURSE_STAGE_SUCCESS: 'DASHBOARD/DOCUMENT/SKIP_COURSE_STAGE_SUCCESS',
  SKIP_COURSE_STAGE_ERROR: 'DASHBOARD/DOCUMENT/SKIP_COURSE_STAGE_ERROR',

  DOCUMENT_CHOSEN: 'DASHBOARD/DOCUMENT/DOCUMENT_CHOSEN',
  DOCUMENT_CANCELLED: 'DASHBOARD/DOCUMENT/DOCUMENT_CANCELLED',
  DOCUMENT_UPLOAD_PROGRESS: 'DASHBOARD/DOCUMENT/DOCUMENT_UPLOAD_PROGRESS',
  DOCUMENT_UPLOAD_FAILED: 'DASHBOARD/DOCUMENT/DOCUMENT_UPLOAD_FAILED',
  DOCUMENT_UPLOAD_COMPLETED: 'DASHBOARD/DOCUMENT/DOCUMENT_UPLOAD_COMPLETED',

  DOCUMENT_NUMBER_CHANGED: 'DASHBOARD/DOCUMENT/DOCUMENT_NUMBER_CHANGED',
  // lumpsum payment details
  FECTH_LUMPSUM_PAYMENT_DETAILS_REQUEST:
    'DASHBOARD/PAYMENT/FECTH_LUMPSUM_PAYMENT_DETAILS_REQUEST',
  FECTH_LUMPSUM_PAYMENT_DETAILS_SUCCESS:
    'DASHBOARD/PAYMENT/FECTH_LUMPSUM_PAYMENT_DETAILS_SUCCESS',
  FECTH_LUMPSUM_PAYMENT_DETAILS_ERROR:
    'DASHBOARD/PAYMENT/FECTH_LUMPSUM_PAYMENT_DETAILS_ERROR',
  // payment hoistory
  FECTH_PAYMENT_HISTORY_REQUEST:
    'DASHBOARD/PAYMENT/FECTH_PAYMENT_HISTORY_REQUEST',
  FECTH_PAYMENT_HISTORY_SUCCESS:
    'DASHBOARD/PAYMENT/FECTH_PAYMENT_HISTORY_SUCCESS',
  FECTH_PAYMENT_HISTORY_ERROR: 'DASHBOARD/PAYMENT/FECTH_PAYMENT_HISTORY_ERROR',

  // additional addons hoistory
  FECTH_ADDITIONAL_ADDONS_HISTORY_REQUEST:
    'DASHBOARD/ADDITIONAL_ADDONS/FECTH_ADDITIONAL_ADDONS_HISTORY_REQUEST',
  FECTH_ADDITIONAL_ADDONS_HISTORY_SUCCESS:
    'DASHBOARD/ADDITIONAL_ADDONS/FECTH_ADDITIONAL_ADDONS_HISTORY_SUCCESS',
  FECTH_ADDITIONAL_ADDONS_HISTORY_ERROR:
    'DASHBOARD/ADDITIONAL_ADDONS/FECTH_ADDITIONAL_ADDONS_HISTORY_ERROR',

  // payment details
  PAYMENT_DETAILS_SELECTED: 'DASHBOARD/PAYMENT/PAYMENT_DETAILS_SELECTED',
  FECTH_PAYMENT_DETAILS_REQUEST:
    'DASHBOARD/PAYMENT/FECTH_PAYMENT_DETAILS_REQUEST',
  FECTH_PAYMENT_DETAILS_SUCCESS:
    'DASHBOARD/PAYMENT/FECTH_PAYMENT_DETAILS_SUCCESS',
  FECTH_PAYMENT_DETAILS_ERROR: 'DASHBOARD/PAYMENT/FECTH_PAYMENT_DETAILS_ERROR',
  RESET_LUMPSUM_PAYMENT_DETAILS:
    'DASHBOARD/PAYMENT/RESET_LUMPSUM_PAYMENT_DETAILS',

  // TAB
  TAB_CHANGED: 'DASHBOARD/TAB_CHNANGED',

  FETCH_DOCUMENTS_URL_REQUEST: 'DASHBOARD/DOCUMENT/FETCH_DOCUMENTS_URL_REQUEST',
  FETCH_DOCUMENTS_URL_SUCCESS: 'DASHBOARD/DOCUMENT/FETCH_DOCUMENTS_URL_SUCCESS',
  FETCH_DOCUMENTS_URL_ERROR: 'DASHBOARD/DOCUMENT/FETCH_DOCUMENTS_URL_ERROR',

  // My referral
  FECTH_MY_REFERAL_REQUEST:
    'DASHBOARD/MYREFERRAL/FECTH_MYREFERRALREPORT_REQUEST',
  FECTH_MY_REFERAL_SUCCESS:
    'DASHBOARD/MYREFERRAL/FECTH_MYREFERRALREPORT_SUCCESS',
  FECTH_MY_REFERAL_ERROR: 'DASHBOARD/MYREFERRAL/FECTH_MYREFERRALREPORT_ERROR',
};

export interface DocumentsState {
  isLoading: boolean;
  isLoaded: boolean;
  items?: Dict<StudentDocument>;
  error?: string;
}

export interface DocumentState {
  isDocumentLoading: boolean;
  isDocumentLoaded?: boolean;
  frontUrl?: string;
  backUrl?: string;
  currentDocumentId?: string;
}

export enum DashboardTabs {
  Dashboard = 'Dashboard',
  Courses = 'Courses',
  Payments = 'Payments',
  Lecture = 'Online Lecture',
  MyAccount = 'My Account',
  TestDetails = 'Test Details',
  Schedules = 'Schedules',
  MockTestSchedules = 'Mock Test Schedules',
  UploadDocument = 'UploadDocument',
}

export interface DashboardState {
  documents: DocumentsState;
  paymentHistory: PaymentHistoryState;
  paymentDetails: PaymentDetailsState;
  lumpsumPaymentDetails: LumpsumPaymentDetailsState;
  currentTab: DashboardTabs;
  document: DocumentState;
  additionalAddonHistory: AdditionalAddonHistoryState;
  myReferrals: MyReferalState;
}

export interface PaymentHistoryState {
  isLoading: boolean;
  isLoaded: boolean;
  items?: PaymentResponse[];
  error?: string;
}

export interface LumpsumPaymentDetailsState {
  isLoading: boolean;
  isLoaded: boolean;
  data?: LumpsumPaymentDetails;
  error?: string;
}

export interface PaymentDetailsState {
  id?: string;
  isLoading: boolean;
  isLoaded: boolean;
  item?: Payment;
  error?: string;
}

export interface AdditionalAddonHistoryState {
  isLoading: boolean;
  isLoaded: boolean;
  items?: AdditionalAddonResponse[];
  error?: string;
}

const initialState: DashboardState = {
  documents: {
    isLoading: false,
    isLoaded: false,
  },
  paymentHistory: {
    isLoading: false,
    isLoaded: false,
  },
  paymentDetails: {
    isLoading: false,
    isLoaded: false,
  },
  currentTab: DashboardTabs.Dashboard,
  document: {
    isDocumentLoading: false,
    isDocumentLoaded: false,
  },
  lumpsumPaymentDetails: {
    isLoading: false,
    isLoaded: false,
  },
  additionalAddonHistory: {
    isLoading: false,
    isLoaded: false,
  },
  myReferrals: {
    isLoading: false,
    isLoaded: false,
  },
};

export interface MyReferalState {
  isLoading: boolean;
  isLoaded: boolean;
  items?: MyReferralResponse[];
  error?: string;
}
// reducer

const initialStudentDocument: StudentDocument = {
  documentNumber: '',
  frontType: 'image',
  frontUrl: '',
  height: 0,
  instruction: '',
  isApproved: false,
  isBackApproved: false,
  isFrontApproved: false,
  name: '',
  pixels: 0,
  requireDocumentNumber: false,
  requireFrontBack: false,
  supportedFormat: [],
  width: 0,
  _id: '',
};

const updateFileDataToState = (
  state: DashboardState,
  docId: string,
  fileData: Partial<StudentDocument>
): DashboardState => {
  return {
    ...state,
    documents: {
      ...state.documents,
      items: {
        ...(state.documents.items || {}),
        [docId]: {
          ...(state.documents.items
            ? state.documents.items[docId]
            : initialStudentDocument),
          ...fileData,
        },
      },
    },
  };
};

const getFileData = (
  state: DashboardState,
  front: boolean,
  docId: string,
  uploadData: Partial<DocumentInfo>
): Partial<StudentDocument> => {
  const fileDataKey = front ? 'frontFileInfo' : 'backFileInfo';
  return {
    [fileDataKey]: {
      ...(state.documents.items || { [docId]: {} })[docId][fileDataKey],
      ...uploadData,
    },
  };
};

const isFileNotUploading = (
  state: DashboardState,
  front: boolean,
  docId: string
): boolean => {
  return (
    !(state.documents.items && state.documents.items[docId]) ||
    (front &&
      !(
        state.documents.items[docId].frontFileInfo &&
        (state.documents.items[docId].frontFileInfo as DocumentInfo)
          .isFileUploading
      )) ||
    (!front &&
      !(
        state.documents.items[docId].backFileInfo &&
        (state.documents.items[docId].backFileInfo as DocumentInfo)
          .isFileUploading
      ))
  );
};

const formatDocumentResponse = (doc: StudentDocument): StudentDocument => {
  const frontFileUploaded: boolean = !!doc.frontUrl;
  const backFileUploaded: boolean = !!doc.backUrl;
  return {
    ...doc,
    frontFileInfo: {
      chosenFileName: doc.frontName,
      chosenFileSize: `${(doc.frontSize || 0) / 1000} KB`,
      isFileChosen: frontFileUploaded,
      isFileUploading: false,
      isUploadingCompleted: frontFileUploaded,
      isApproved: doc.isFrontApproved,
      uploadedFileInfo: {
        fileType: doc.frontType,
        name: doc.frontName,
        size: doc.frontSize,
      },
    },
    backFileInfo: doc.requireFrontBack
      ? {
          chosenFileName: doc.backName,
          chosenFileSize: `${(doc.backSize || 0) / 1000} KB`,
          isFileChosen: backFileUploaded,
          isFileUploading: false,
          isUploadingCompleted: backFileUploaded,
          isApproved: doc.isBackApproved,
          uploadedFileInfo: {
            fileType: doc.backType,
            name: doc.backName,
            size: doc.backSize,
          },
        }
      : undefined,
  };
};

export default (
  state: DashboardState = initialState,
  action: any
): DashboardState => {
  switch (action.type) {
    case commonTypes.RESET_DATA:
      return {
        ...initialState,
      };
    // tab
    case types.TAB_CHANGED:
      return {
        ...state,
        currentTab: action.data.tab,
      };
    // documents
    case types.FETCH_DOCUMENTS_REQUEST:
      return {
        ...state,
        documents: {
          ...state.documents,
          error: undefined,
          isLoading: true,
          isLoaded: false,
        },
      };
    case types.FETCH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: {
          ...state.documents,
          items: action.data.reduce(
            (prev: Dict<StudentDocument>, cur: StudentDocument) => {
              prev[cur._id] = formatDocumentResponse(cur);
              return prev;
            },
            {}
          ),
          error: undefined,
          isLoading: false,
          isLoaded: true,
        },
      };
    case types.FETCH_DOCUMENTS_ERROR:
      return {
        ...state,
        documents: {
          ...state.documents,
          error: action.data.errorMessage,
          isLoading: false,
          isLoaded: true,
        },
      };
    // documents
    case types.FETCH_DOCUMENTS_URL_REQUEST:
      return {
        ...state,
        document: {
          isDocumentLoading: true,
          isDocumentLoaded: false,
        },
      };
    case types.FETCH_DOCUMENTS_URL_SUCCESS:
      return {
        ...state,
        document: {
          frontUrl: action.front ? action.data : state.document.frontUrl,
          backUrl: action.front ? state.document.backUrl : action.data,
          isDocumentLoading: false,
          isDocumentLoaded: true,
          currentDocumentId: action.documentId,
        },
      };
    case types.FETCH_DOCUMENTS_URL_ERROR:
      return {
        ...state,
        document: {
          isDocumentLoading: false,
          isDocumentLoaded: true,
        },
      };

    case types.FECTH_PAYMENT_HISTORY_REQUEST:
      return {
        ...state,
        paymentHistory: {
          ...state.paymentHistory,
          error: undefined,
          isLoading: true,
          isLoaded: false,
        },
      };
    case types.FECTH_PAYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        ...state,
        paymentHistory: {
          ...state.paymentHistory,
          items: action.data,
          error: undefined,
          isLoading: false,
          isLoaded: true,
        },
      };
    case types.FECTH_PAYMENT_HISTORY_ERROR:
      return {
        ...state,
        paymentHistory: {
          ...state.paymentHistory,
          error: action.data.errorMessage,
          isLoading: false,
          isLoaded: true,
        },
      };
    case types.FECTH_PAYMENT_DETAILS_REQUEST:
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          error: undefined,
          isLoading: true,
          isLoaded: false,
        },
      };
    case types.FECTH_PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          item: action.data,
          error: undefined,
          isLoading: false,
          isLoaded: true,
        },
      };
    case types.FECTH_PAYMENT_DETAILS_ERROR:
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          error: action.data.errorMessage,
          isLoading: false,
          isLoaded: false,
        },
      };
    case types.FECTH_LUMPSUM_PAYMENT_DETAILS_REQUEST:
      return {
        ...state,
        lumpsumPaymentDetails: {
          ...state.lumpsumPaymentDetails,
          error: undefined,
          isLoading: true,
          isLoaded: false,
        },
      };
    case types.FECTH_LUMPSUM_PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        lumpsumPaymentDetails: {
          ...state.lumpsumPaymentDetails,
          data: action.data,
          error: undefined,
          isLoading: false,
          isLoaded: true,
        },
      };
    case types.FECTH_LUMPSUM_PAYMENT_DETAILS_ERROR:
      return {
        ...state,
        lumpsumPaymentDetails: {
          ...state.lumpsumPaymentDetails,
          error: action.data.errorMessage,
          isLoading: false,
          isLoaded: true,
        },
      };
    case types.RESET_LUMPSUM_PAYMENT_DETAILS:
      return {
        ...state,
        lumpsumPaymentDetails: {
          data: undefined,
          error: undefined,
          isLoading: false,
          isLoaded: false,
        },
      };

    case types.PAYMENT_DETAILS_SELECTED:
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          id: action.data.id,
        },
      };
    case types.DOCUMENT_NUMBER_CHANGED:
      return updateFileDataToState(state, action.data.docId, {
        documentNumber: action.data.docNumber,
      });

    case types.DOCUMENT_UPLOAD_PROGRESS: {
      if (!state.documents.items) {
        return state;
      }
      const {
        progress,
        front = true,
      }: { progress: number; front?: boolean } = action.data;

      if (isFileNotUploading(state, front, action.data._id)) {
        return state;
      }

      const uploadData: Partial<DocumentInfo> = {
        uploadProgress: progress,
        isUploadingCompleted: false,
      };

      const fileData: Partial<StudentDocument> = getFileData(
        state,
        front,
        action.data._id,
        uploadData
      );
      return updateFileDataToState(state, action.data._id, fileData);
    }
    case types.DOCUMENT_UPLOAD_COMPLETED: {
      if (!state.documents.items) {
        return state;
      }
      const {
        front = true,
        uploadedFileInfo,
      }: { front?: boolean; uploadedFileInfo: UploadedFileInfo } = action.data;

      if (isFileNotUploading(state, front, action.data._id)) {
        return state;
      }

      const uploadData = {
        isFileUploading: false,
        isUploadingCompleted: true,
        uploadedFileInfo,
      };
      const fileData: Partial<StudentDocument> = getFileData(
        state,
        front,
        action.data._id,
        uploadData
      );

      return updateFileDataToState(state, action.data._id, fileData);
    }
    case types.DOCUMENT_CHOSEN: {
      const {
        file,
        front = true,
      }: { file: File; front?: boolean } = action.data;

      const uploadData = {
        isFileChosen: true,
        chosenFileName: file.name,
        chosenFileSize: `${file.size / 1000} KB`,
        isFileUploading: true,
        isUploadingCompleted: false,
        uploadProgress: 0,
      };

      const fileData: Partial<StudentDocument> = getFileData(
        state,
        front,
        action.data._id,
        uploadData
      );

      return updateFileDataToState(state, action.data._id, fileData);
    }
    case types.DOCUMENT_CANCELLED: {
      const { front = true }: { front?: boolean } = action.data;

      const uploadData: DocumentInfo = {
        isFileChosen: false,
        chosenFileName: undefined,
        chosenFileSize: undefined,
        isFileUploading: false,
        isUploadingCompleted: false,
        uploadProgress: 0,
        uploadedFileInfo: undefined,
        isApproved: false,
      };
      const fileData: Partial<StudentDocument> = getFileData(
        state,
        front,
        action.data._id,
        uploadData
      );
      return updateFileDataToState(state, action.data._id, fileData);
    }
    case types.DOCUMENT_UPLOAD_FAILED: {
      const { front = true }: { front?: boolean } = action.data;
      const uploadData = {
        isFileChosen: false,
        chosenFrontFileName: undefined,
        chosenFrontFileSize: undefined,
        frontFileUploadProgress: undefined,
        isFrontFileUploading: false,
        isFrontFileUploadingCompleted: false,
      };
      const fileData: Partial<StudentDocument> = getFileData(
        state,
        front,
        action.data._id,
        uploadData
      );
      return updateFileDataToState(state, action.data._id, fileData);
    }
    case types.FECTH_ADDITIONAL_ADDONS_HISTORY_ERROR:
      return {
        ...state,
        additionalAddonHistory: {
          ...state.additionalAddonHistory,
          error: action.data.errorMessage,
          isLoading: false,
          isLoaded: true,
        },
      };

    case types.FECTH_ADDITIONAL_ADDONS_HISTORY_REQUEST:
      return {
        ...state,
        additionalAddonHistory: {
          ...state.additionalAddonHistory,
          error: undefined,
          isLoading: true,
          isLoaded: false,
        },
      };
    case types.FECTH_ADDITIONAL_ADDONS_HISTORY_SUCCESS:
      return {
        ...state,
        ...state,
        additionalAddonHistory: {
          ...state.additionalAddonHistory,
          items: action.data,
          error: undefined,
          isLoading: false,
          isLoaded: true,
        },
      };
    case types.FECTH_MY_REFERAL_REQUEST:
      return {
        ...state,
        ...state,
        myReferrals: {
          ...state.myReferrals,
          items: action.data,
          error: undefined,
          isLoading: false,
          isLoaded: true,
        },
      };

    case types.FECTH_MY_REFERAL_SUCCESS:
      return {
        ...state,
        ...state,
        myReferrals: {
          ...state.myReferrals,
          items: action.data,
          error: undefined,
          isLoading: false,
          isLoaded: true,
        },
      };
    case types.FECTH_MY_REFERAL_ERROR:
      return {
        ...state,
        ...state,
        myReferrals: {
          ...state.myReferrals,
          error: action.data.errorMessage,
          isLoading: false,
          isLoaded: true,
        },
      };

    default:
      return state;
  }
};

// action creators & async actions
export const actions = {
  changeTab: (tab: DashboardTabs) => (dispatch: Dispatch) =>
    dispatch({ type: types.TAB_CHANGED, data: { tab } }),
  fetchDocuments: (overrideToken?: string) => async (dispatch: Dispatch) => {
    dispatch({ type: types.FETCH_DOCUMENTS_REQUEST });
    try {
      const response = await api.user.getStudentDocuments(overrideToken);
      dispatch({
        type: types.FETCH_DOCUMENTS_SUCCESS,
        data: response.data.documents,
      });
    } catch (error) {
      // console.log(error);
      // console.log(error.response);
      // console.log('error response: ', error.response);
      dispatch({
        type: types.FETCH_DOCUMENTS_ERROR,
        data: { errorMessage: 'Unable to load documents' },
      });
      // toastr.error('Error', error.response.data.message);
      // errorHandler(error);
      // throw error;
    }
  },
  fetchPaymentHistory: () => async (dispatch: Dispatch) => {
    dispatch({ type: types.FECTH_PAYMENT_HISTORY_REQUEST });
    try {
      const response = await api.user.getPaymentHistory();
      dispatch({
        type: types.FECTH_PAYMENT_HISTORY_SUCCESS,
        data: response.data,
      });
    } catch (error) {
      // console.log(error);
      // console.log(error.response);
      // console.log('error response: ', error.response);
      dispatch({
        type: types.FECTH_PAYMENT_HISTORY_ERROR,
        data: { errorMessage: 'Unable to load payment history' },
      });
      // toastr.error('Error', error.response.data.message);
      // errorHandler(error);
      // throw error;
    }
  },

  fetchAdditionalAddonHistory: (userId: string) => async (
    dispatch: Dispatch
  ) => {
    dispatch({ type: types.FECTH_ADDITIONAL_ADDONS_HISTORY_REQUEST });
    try {
      const response = await api.user.getRTAAddonDetails(userId);
      dispatch({
        type: types.FECTH_ADDITIONAL_ADDONS_HISTORY_SUCCESS,
        data: response.data,
      });
    } catch (error) {
      // console.log(error);
      // console.log(error.response);
      // console.log('error response: ', error.response);
      dispatch({
        type: types.FECTH_ADDITIONAL_ADDONS_HISTORY_ERROR,
        data: { errorMessage: 'Unable to load payment history' },
      });
      // toastr.error('Error', error.response.data.message);
      // errorHandler(error);
      // throw error;
    }
  },

  fetchLumpsumPaymentDetails: (
    courseId: string,
    invoiceNumber?: string
  ) => async (dispatch: Dispatch) => {
    dispatch({ type: types.FECTH_LUMPSUM_PAYMENT_DETAILS_REQUEST });
    try {
      const response = await api.user.getLumpsumPaymentDetails(
        courseId,
        invoiceNumber
      );
      dispatch({
        type: types.FECTH_LUMPSUM_PAYMENT_DETAILS_SUCCESS,
        data: response.data,
      });
    } catch (error) {
      // console.log(error);
      // console.log(error.response);
      // console.log('error response: ', error.response);
      dispatch({
        type: types.FECTH_LUMPSUM_PAYMENT_DETAILS_ERROR,
        data: { errorMessage: 'Unable to load payment details' },
      });
      // toastr.error('Error', error.response.data.message);
      // errorHandler(error);
      // throw error;
    }
  },
  resetLumpsumPaymentDetails: () => async (dispatch: Dispatch) => {
    dispatch({ type: types.RESET_LUMPSUM_PAYMENT_DETAILS });
  },
  fetchPaymentDetails: (paymentId: string) => async (dispatch: Dispatch) => {
    dispatch({ type: types.FECTH_PAYMENT_DETAILS_REQUEST });
    try {
      const response = await api.user.getSinglePaymentDetails(paymentId);
      dispatch({
        type: types.FECTH_PAYMENT_DETAILS_SUCCESS,
        data: response.data,
      });
    } catch (error) {
      // console.log(error);
      // console.log(error.response);
      // console.log('error response: ', error.response);
      dispatch({
        type: types.FECTH_PAYMENT_DETAILS_ERROR,
        data: { errorMessage: 'Unable to load payment details' },
      });
      // toastr.error('Error', error.response.data.message);
      // errorHandler(error);
      // throw error;
    }
  },

  fetchMyReferralReport: (id: string) => async (dispatch: Dispatch) => {
    dispatch({ type: types.FECTH_MY_REFERAL_REQUEST });
    try {
      const response = await api.user.getMyReferralReport(id);
      dispatch({
        type: types.FECTH_MY_REFERAL_SUCCESS,
        data: response.data,
      });
    } catch (error) {
      // console.log(error);
      // console.log(error.response);
      // console.log('error response: ', error.response);
      dispatch({
        type: types.FECTH_PAYMENT_HISTORY_ERROR,
        data: { errorMessage: 'Unable to load Referral Report' },
      });
      // toastr.error('Error', error.response.data.message);
      // errorHandler(error);
      // throw error;
    }
  },

  skipStage: (stageId: string, onComplete?: () => void) => async (
    dispatch: Dispatch
  ) => {
    dispatch({ type: types.SKIP_COURSE_STAGE_REQUEST });
    try {
      const response = await api.user.skipCourseStage({ stageId });
      dispatch({
        type: types.SKIP_COURSE_STAGE_SUCCESS,
        data: response.data,
      });
    } catch (error) {
      // console.log(error);
      // console.log(error.response);
      // console.log('error response: ', error.response);
      dispatch({
        type: types.SKIP_COURSE_STAGE_ERROR,
        data: { errorMessage: 'Unable to skip this stage' },
      });
      // toastr.error('Error', error.response.data.message);
      // errorHandler(error);
      // throw error;
    }

    if (onComplete) {
      onComplete();
    }
  },
  downloadInvoice: (
    paymentId: string,
    isAdvancePayment: boolean = false,
    onComplete?: () => void
  ) => async (dispatch: Dispatch) => {
    try {
      const response = await api.user.downloadInvoicePdf(
        paymentId,
        isAdvancePayment
      );
      const data = new Blob([response.data], { type: 'application/pdf' });
      saveAs(data, `invoice-${paymentId}.pdf`);
    } catch (error) {
      const typedError = error as ErrorResponse;

      toastr.error(
        'Error',
        (typedError &&
          typedError.response &&
          typedError.response.data &&
          typedError.response.data.message) ||
          'Failed to download pdf'
      );
    } finally {
      if (onComplete) {
        onComplete();
      }
    }
  },
  downloadLumpsumInvoice: (
    courseId: string,
    invoiceNumber?: string,
    onComplete?: () => void
  ) => async (dispatch: Dispatch) => {
    try {
      const response = await api.user.downloadLumpsumInvoicePdf(
        courseId,
        invoiceNumber
      );
      const data = new Blob([response.data], { type: 'application/pdf' });
      saveAs(data, `invoice-${courseId}.pdf`);
    } catch (error) {
      const typedError = error as ErrorResponse;

      toastr.error(
        'Error',
        (typedError &&
          typedError.response &&
          typedError.response.data &&
          typedError.response.data.message) ||
          'Failed to download pdf'
      );
    } finally {
      if (onComplete) {
        onComplete();
      }
    }
  },
  fileChosen: (docId: string, file: File, front: boolean = true) => async (
    dispatch: Dispatch,
    getState: () => State
  ) => {
    dispatch({
      type: types.DOCUMENT_CHOSEN,
      data: { _id: docId, file, front },
    });
    try {
      const onUploadProgress = (progress: ProgressEvent) => {
        const progressPercentage = progress.loaded / progress.total;
        dispatch({
          type: types.DOCUMENT_UPLOAD_PROGRESS,
          data: {
            progress: progressPercentage,
            _id: docId,
            front,
          },
        });
      };
      const response = await api.media.upload(file, onUploadProgress);
      const fileInfo: UploadedFileInfo = response.data.data;
      await api.user.uploadDocument({
        _id: docId,
        fileType: fileInfo.fileType as SupportedFileTypes,
        uri: fileInfo.uri as string,
        front,
        name: fileInfo.name || '',
        size: fileInfo.size || 0,
      });

      dispatch({
        type: types.DOCUMENT_UPLOAD_COMPLETED,
        data: {
          _id: docId,
          front,
          uploadedFileInfo: response.data.data,
        },
      });

      const state = getState();
      if (isAllDocumentsUpdationCompleted(state.dashboard.documents.items)) {
        toastr.success('Thank You.', 'Your documents are submited for review.');
      }
    } catch (error) {
      toastr.error(
        'Error',
        `Failed to upload file ${file.name}, Please try again`
      );
      dispatch({
        type: types.DOCUMENT_UPLOAD_FAILED,
        data: {
          _id: docId,
          front,
        },
      });
    }
  },
  documentNumberChanged: (
    docId: string,
    docNumber: string,
    onSuccess?: () => void,
    onError?: () => void
  ) => async (dispatch: Dispatch) => {
    try {
      await api.user.updateDocumentNumber(docId, docNumber);

      dispatch({
        type: types.DOCUMENT_NUMBER_CHANGED,
        data: {
          docId,
          docNumber,
        },
      });

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (onError) {
        onError();
      }
    }
  },
  onUploadProgress: (
    docId: string,
    file: File,
    progress: number,
    front: boolean = true
  ) => async (dispatch: Dispatch) => {
    dispatch({
      type: types.DOCUMENT_UPLOAD_PROGRESS,
      data: { _id: docId, file, front, progress },
    });
  },
  fileClosed: (docId: string, front: boolean = true) => async (
    dispatch: Dispatch
  ) => {
    dispatch({ type: types.DOCUMENT_CANCELLED, data: { _id: docId, front } });
  },
  sendVerificationMail: (onComplete?: () => void) => async (
    dispatch: Dispatch
  ) => {
    try {
      const response = await api.user.sendVerificationEmail();
      toastr.success('Success', response.data.message);
    } catch (error) {
      toastr.error('Error', 'Unable to send verification mail');
    } finally {
      if (onComplete) {
        onComplete();
      }
    }
  },
  actionAdditionalTrainingRequest: (
    isApproved: boolean,
    onComplete?: () => void
  ) => async (dispatch: Dispatch) => {
    try {
      const response = await api.user.actionAdditionalTrainingRequest(
        isApproved
      );

      toastr.success('Success', response.data.message);
    } catch (error) {
      toastr.error('Error', 'Something went wrong! Please try again');
    } finally {
      if (onComplete) {
        onComplete();
      }
    }
  },
  getSignedDocumentUrl: (
    docFilepath: string,
    documentId: string,
    front: boolean,
    overrideToken?: string
  ) => async (dispatch: Dispatch) => {
    dispatch({ type: types.FETCH_DOCUMENTS_URL_REQUEST });
    try {
      const response = await api.media.getSignedDocumentUrl(
        docFilepath,
        overrideToken
      );
      dispatch({
        type: types.FETCH_DOCUMENTS_URL_SUCCESS,
        data: response.data,
        documentId,
        front,
      });
    } catch (error) {
      // console.log(error);
      // console.log(error.response);
      // console.log('error response: ', error.response);
      dispatch({
        type: types.FETCH_DOCUMENTS_URL_ERROR,
        data: { errorMessage: 'Unable to load documents' },
      });
      // toastr.error('Error', error.response.data.message);
      // errorHandler(error);
      // throw error;
    }
  },
};
