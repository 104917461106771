import React, { Component } from 'react';

import './PaymentPlanOption.scss';

interface CustomProps {
  selected: boolean;
  onSelect?: () => void;
  id: string;
  name: string;
  description?: string;
  totalAmount?: number;
  totalRtaAmount?: number;
  totalEdsAmount?: number;
  showTooltip?: boolean;
  primary?: boolean;
}
class PaymentPlanOption extends Component<CustomProps> {
  renderInput() {
    return (
      <input
        type="radio"
        id={this.props.id}
        checked={this.props.selected}
        onClick={this.props.onSelect}
        onChange={() => {
          return;
        }}
      />
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderLabelIcon() {
    return (
      <div className="PaymentPlanRadio__round">
        <span className="circle-fill">
          <i className="icon-tick" />
        </span>
      </div>
    );
  }

  renderLabelText() {
    return (
      <div className="px-2 font-medium">
        <h4 className="text-base mb-1">{this.props.name}</h4>
        <p className="text-sm">{this.props.description}</p>
      </div>
    );
  }

  renderTooltipRtaAmount() {
    return (
      <span className="PaymentPlan__tooltip-item">
        RTA Fee -{' '}
        <span className="PaymentPlan__tooltip-price">
          AED {(this.props.totalRtaAmount || 0).toFixed(2)}
        </span>
      </span>
    );
  }

  renderTooltipEdsAmount() {
    return (
      <span className="PaymentPlan__tooltip-item">
        EDC Fee -{' '}
        <span className="PaymentPlan__tooltip-price">
          AED {(this.props.totalEdsAmount || 0).toFixed(2)}
        </span>
      </span>
    );
  }

  renderAmountTooltip() {
    return (
      <div className="PaymentPlan__tooltip Show">
        {this.renderTooltipRtaAmount()}
        {this.renderLabelAmount()}
      </div>
    );
  }

  renderLabelAmount() {
    return (
      <span className="px-2 text-lg font-bold ltr:ml-auto rtl:mr-auto self-center">
        AED {this.props.totalAmount}
        {/* {this.props.showTooltip && this.renderAmountTooltip()} */}
      </span>
    );
  }

  renderLabel() {
    return (
      <label
        className={`block w-full border border-[#CACACA] !px-6 py-4 rounded-md before:!hidden mb-5 ${
          this.props.selected ? 'bg-slate-50' : ''
        }`}
        htmlFor={this.props.id}
      >
        <div className="flex -mx-2 py-0.5 my-px">
          <span className="px-2 pt-0.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              className={`rounded-full ${
                this.props.selected ? 'text-secondary' : 'text-white'
              }`}
            >
              <circle
                cx="10"
                cy="10"
                r="10"
                fill="currentColor"
                stroke={this.props.selected ? '#ef3935' : '#666'}
              />
              <path
                d="M9.01727 13.2538C9.34127 13.4677 9.78156 13.4321 10.0668 13.1468C10.0685 13.1451 10.0703 13.1434 10.072 13.1416L14.7887 8.42495C15.1146 8.09897 15.1146 7.57046 14.7887 7.24449C14.4627 6.9185 13.9342 6.9185 13.6082 7.24449L9.47658 11.3761L7.42495 9.32447C7.09898 8.9985 6.57046 8.9985 6.24448 9.32447C5.91851 9.65044 5.91851 10.179 6.24448 10.5049L8.8808 13.1412C8.88263 13.1431 8.88447 13.145 8.88632 13.1468C8.92707 13.1876 8.97098 13.2232 9.01727 13.2538Z"
                fill="white"
                stroke="none"
              />
            </svg>
          </span>
          {this.renderLabelText()}
          {this.renderLabelAmount()}
        </div>
      </label>
    );
  }

  render() {
    return (
      <div className="custom-radio payment">
        {this.renderInput()}
        {this.renderLabel()}
      </div>
    );
  }
}

// PaymentPlanOption.propTypes = {
//   selected: PropTypes.bool.isRequired,
//   onSelect: PropTypes.func.isRequired,
//   id: PropTypes.string.isRequired,
//   name: PropTypes.string.isRequired,
//   description: PropTypes.string.isRequired,
//   totalAmount: PropTypes.number.isRequired,
//   totalRtaAmount: PropTypes.number.isRequired,
//   totalEdsAmount: PropTypes.number.isRequired,
//   showTooltip: PropTypes.bool,
//   primary: PropTypes.bool,
// };

export default PaymentPlanOption;
