import React, { Component } from 'react';
// import PropTypes from 'prop-types';

import './CoursePlan.scss';
import Button from '../../button';
import { formatMultiLanguageText } from '../../../utils/multi-language-utils';
import { StudentCourse } from '../../../models/user';

interface CustomProps {
  course: StudentCourse;
  onSelect: (course: any) => void;
  onChangeExpanded: () => void;
  isExpanded: boolean;
  selectedId?: string;
  disableSelect: boolean;
}

interface CustomState {
  isMore: boolean;
}

class CoursePlan extends Component<CustomProps, CustomState> {
  constructor(props: CustomProps) {
    super(props);
    this.state = {
      isMore: false,
    };
  }

  renderPlanHeaderAmount() {
    return (
      <h2 className="md:text-2xl text-lg md:leading-normal font-medium md:font-semibold">
        AED{' '}
        {this.props.course.amount.totalAmount -
          this.props.course.amount.totalDiscount}
      </h2>
    );
  }

  getAmountPerClass(): number | undefined {
    if (this.props.course.courseType.isLumpSumCourse) {
      return undefined;
    }
    const stage = this.props.course.stages.find(
      (st) =>
        st.feeCodes.find((fc) =>
          this.props.course.course.amountPerClassFeeCodes.includes(fc.code)
        ) !== undefined
    );
    if (!stage) {
      return undefined;
    }

    const feeCode = stage.feeCodes.find((fc) =>
      this.props.course.course.amountPerClassFeeCodes.includes(fc.code)
    );

    if (!feeCode) {
      return undefined;
    }
    return feeCode.amountPerQuantity * (stage.noOfClassesPerHour || 1);
  }

  renderLumpSumDisclaimer() {
    return (
      <p className="text-[13px] text-muted max-w-[180px] md:max-w-[300px]">
        (License Guaranteed)<sup className="text-primary">**</sup>
      </p>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderPlanHeaderCourseTypeName(courseType: string) {
    return <span className="Plan__type">{courseType}</span>;
  }

  // eslint-disable-next-line class-methods-use-this
  renderPlanHeaderNote() {
    return (
      <p className="text-[10px] lg:text-[13px] text-muted max-w-[180px] md:max-w-[300px]">
        (5% VAT applicable on {process.env.REACT_APP_COMPANYNAME} Fees)
      </p>
    );
  }

  renderPlanHeaderSelectButton(courseType: string) {
    return (
      <div className="Plan__btn">
        <Button
          type="button"
          size="sm"
          onClick={() => {
            this.props.onSelect(this.props.course);
          }}
        >
          Choose {courseType}
        </Button>
      </div>
    );
  }

  renderPlanHeaderOriginalCourseAmount() {
    return (
      <span className="text-muted line-through">
        AED {this.props.course.amount.totalAmount}{' '}
      </span>
    );
  }

  renderPlanHeaderDiscountInfo() {
    return (
      <span className="text-primary">
        You save AED {this.props.course.amount.totalDiscount}
      </span>
    );
  }

  renderPlanHeader() {
    let courseType;
    const customLumpsumCourses = ["LMTL2"];
    if (this.props.course && this.props.course.courseType) {
      const courseTypeCode:any = this.props.course?.courseType?.code;
      if (this.props.course?.courseType?.isLumpSumCourse) {
        if (this.props.course?.courseType?.isVipCourse) {
          courseType = 'VIP Lumpsum';
        }else if(customLumpsumCourses?.includes(courseTypeCode) ){ 
          courseType = this.props.course.courseType.name.en;
        }else {
          courseType = 'Lumpsum';
        }
      } else {
        courseType = this.props.course.courseType.name.en;
      }
    }
    return (
      <>
        <div className="flex justify-between">
          <div
            className="custom-radio whitespace-nowrap"
            onClick={() => {
              if ( !this.props.disableSelect) {
                this.props.onSelect(this.props.course);
              }
            }}
          >
            <input
              type="radio"
              name="plan"
              id={courseType}
              value={courseType}
              // onClick={() => setPlanselect("regular")}
              // onChange={() => this.props.onSelect(this.props.course)}
              checked={
                this.props.selectedId === this.props.course.courseTypeId
                  ? true
                  : undefined
              }
            />
            <label htmlFor={courseType} className="planlabel">
              {courseType}
            </label>
          </div>
          <div className="text-right">
            {this.renderPlanHeaderAmount()}
            <p className="text-sm">
              {this.props.course.amount.totalDiscount > 0 &&
                this.renderPlanHeaderOriginalCourseAmount()}
              {this.props.course.amount.totalDiscount > 0 &&
                this.renderPlanHeaderDiscountInfo()}
            </p>
            {this.renderPlanHeaderNote()}
            {this.props.course.courseType.isLumpSumCourse &&
              this.renderLumpSumDisclaimer()}
          </div>
        </div>
        <div className="sm:ltr:pl-[30px] sm:rtl:pr-[30px] md:-mt-3">
          {!this.props.course?.courseType?.isLumpSumCourse && this.getAmountPerClass() && (
            <p className="text-sm">AED {this.getAmountPerClass()} /per hour</p>
          )}
          <p className="text-[13px] text-muted">
            This course is suggested by your driving license experience and
            Nationality
          </p>
          {this.props.course.courseType.code === 'LMTL2' && (
            <p className="text-[13px] text-muted">Any additional will be charge seperately</p>
          )}
        </div>
      </>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderPlanListItem(
    label: string,
    value: string | number,
    key: string | number
  ) {
    return (
      <div className=" py-2.5 font-medium flex" key={`${key}`}>
        <span className="pt-0.5 ltr:pr-3.5 rtl:pl-3.5 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="white"
          >
            <circle cx="9" cy="9.5" r="9" fill="black" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.67913 12.6193C7.94011 12.8803 8.36323 12.8803 8.62421 12.6193L14.2041 7.03925C14.4651 6.77827 14.4651 6.35515 14.2041 6.09417C13.9431 5.83319 13.52 5.83319 13.259 6.09417L7.67913 11.6742C7.41815 11.9352 7.41815 12.3583 7.67913 12.6193Z"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.69622 8.74847C4.9572 8.48749 5.38032 8.48749 5.6413 8.74847L8.47653 11.5837C8.7375 11.8447 8.7375 12.2678 8.47653 12.5288C8.21555 12.7897 7.79243 12.7897 7.53145 12.5288L4.69622 9.69354C4.43524 9.43257 4.43524 9.00944 4.69622 8.74847Z"
            />
          </svg>
        </span>
        <div className="grid grid-cols-10 gap-x-2 flex-grow">
          <div className="md:col-span-3 sm:col-span-4 col-span-12 text-sm lg:text-base">
            {label}
          </div>
          <div className="md:col-span-7 sm:col-span-8 col-span-12 text-muted text-sm">
            : {value}
          </div>
        </div>
      </div>
    );
  }

  renderReadMoreLessButton() {
    return (
      <span
        className="text-sm text-secondary font-medium inline-block cursor-pointer mt-3.5"
        onClick={() => this.setState({ isMore: !this.state.isMore })}
      >
        {this.state.isMore === true ? 'View Less' : 'View More'}
      </span>
    );
  }

  renderInfoList() {
    const { courseType } = this.props.course;
    const infoList = [
      {
        label: 'Week Sequence',
        value:
          courseType.weekSequences && courseType.weekSequences.length
            ? formatMultiLanguageText(courseType.weekSequences[0].name) ||
              'All days'
            : 'All days',
      },
      {
        label: 'Number of hours per week',
        value: `${courseType.noOfHoursPerWeek} hrs`,
      },
      {
        label: 'Time allowed in the class type',
        value: '8.30 AM-5.30 PM & 8.00 PM-11.00 PM',
      },
    ];

    if (this.getAmountPerClass()) {
      infoList.push({
        label: 'Training fees per hour',
        value: courseType.isLumpSumCourse
          ? 'N/A'
          : `AED ${this.getAmountPerClass()}`,
      });
    }

    infoList.push(
      {
        label: 'Minimum Booking duration',
        value: `${courseType.minimumClassDuration} hr`,
      },
      {
        label: 'Maximum Booking duration',
        value: `${courseType.maximumClassDuration} hr`,
      }
    );

    return (
      // <ul className={`Plan__list ${this.props.isExpanded ? 'Expanded' : ''}`}>
      // this.props.isExpanded && (
      this.state.isMore === true && (
        <div className="pb-1 pt-4 points">
          {infoList.map((info, index) =>
            this.renderPlanListItem(info.label, info.value, index)
          )}
        </div>
      )
    );
  }

  render() {
    return (
      <div
        className={`border border-[#CACACA] rounded-md p-4 md:p-6 mb-5 ${
          this.state.isMore === true ? 'bg-slate-50' : ''
        }`}
      >
        {this.renderPlanHeader()}
        <div className="sm:ltr:pl-8 sm:rtl:pr-8">
          {this.renderInfoList()}
          {this.renderReadMoreLessButton()}
        </div>
      </div>
    );
  }
}

export default CoursePlan;
