import React, { useState } from 'react';
import { Dict } from '../../models/interfaces';
import { UAELicenseDetails, LicenseDetails } from '../../models/user';
import { validators, validateFields } from '../helpers';
import SelectInput from '../../components/form-group/SelectInput';
import { DateInput } from '../../components/form-group';
import Button from '../../components/button';
import './LicenseTypeForm.scss';

interface CustomProps {
  index: number;
  licenceInfo: Partial<UAELicenseDetails>;
  validate?: boolean;
  disabled?: boolean;
  onChange?: (index: number, licenceInfo: Partial<UAELicenseDetails>) => void;
  onDelete?: (index: number) => void;
  showRemove?: boolean;
}

type Keys =
  | 'licenseTypeId'
  | 'licenseExpiryDate'
  | 'licenseIssueDate'
  | 'licenseEmirate';

const UAELicenseTypeForm: React.SFC<CustomProps> = (props) => {
  const getLicenseTypeIdsField = () => ({
    value: (props.licenceInfo && props.licenceInfo.licenseTypeId) || '',
    name: 'License',
    label: 'Select License *',
    error: '',
    placeholder: 'Select License',
    validators: [validators.required],
  });

  const getLicenceEmirateField = () => {
    return {
      value: (props.licenceInfo && props.licenceInfo.licenseEmirate) || '',
      name: 'Select Emirate',
      label: 'License From Which Emirate *',
      error: '',
      placeholder: 'Choose Emirate',
      validators: [validators.required],
    };
  };

  const getLicenceIssueField = () => {
    return {
      value: (props.licenceInfo && props.licenceInfo.licenseIssueDate) || '',
      name: 'Valid From',
      label: 'Valid From *',
      error: '',
      placeholder: 'DD / MM / YYYY',
      validators: [validators.required],
    };
  };

  const getLicenceExpiryField = () => {
    return {
      value: (props.licenceInfo && props.licenceInfo.licenseExpiryDate) || '',
      name: 'Valid To',
      label: 'Valid To *',
      error: '',
      placeholder: 'DD / MM / YYYY',
      validators: [validators.required],
    };
  };

  let formFields: Dict = {
    licenseTypeId: getLicenseTypeIdsField(),
    licenseExpiryDate: getLicenceExpiryField(),
    licenseIssueDate: getLicenceIssueField(),
    licenseEmirate: getLicenceEmirateField(),
  };

  if (props.validate) {
    const { areFieldsValid, updatedFields } = validateFields({
      fieldsToValidate: formFields,
    });
    if (!areFieldsValid) {
      formFields = updatedFields;
    }
  }

  const fields = formFields;

  const prepareData = (licenseFields: Dict): Partial<UAELicenseDetails> => {
    return {
      licenseEmirate: licenseFields.licenseEmirate.value,
      licenseExpiryDate: licenseFields.licenseExpiryDate.value,
      licenseIssueDate: licenseFields.licenseIssueDate.value,
      licenseTypeId: licenseFields.licenseTypeId.value,
    };
  };

  const changeData = (key: Keys, value: any) => {
    const updatedFields = {
      ...fields,
      [key]: {
        ...fields[key],
        value,
      },
    };

    if (props.onChange) {
      props.onChange(props.index, prepareData(updatedFields));
    }
  };

  const renderLicenceExpiryDateInput = () => {
    return (
      <DateInput
        label={fields.licenseExpiryDate.label}
        onChange={(value) => changeData('licenseExpiryDate', value)}
        placeholder={fields.licenseExpiryDate.placeholder}
        error={fields.licenseExpiryDate.error}
        default={fields.licenseExpiryDate.value}
        // disabled={props.disabled}
        minDate={fields.licenseIssueDate.value}
        className="form-control"
      />
    );
  };

  const renderLicenceIssueDateInput = () => {
    return (
      <DateInput
        label={fields.licenseIssueDate.label}
        onChange={(value) => changeData('licenseIssueDate', value)}
        placeholder={fields.licenseIssueDate.placeholder}
        error={fields.licenseIssueDate.error}
        default={fields.licenseIssueDate.value}
        // disabled={this.props.isLoading}
        maxDate={fields.licenseExpiryDate.value || new Date()}
        className="form-control"
      />
    );
  };

  const renderEmirateInput = () => {
    return (
      <SelectInput
        api="entity/v1/emirates"
        label={fields.licenseEmirate.label}
        onChange={(value) => changeData('licenseEmirate', value)}
        placeholder={fields.licenseEmirate.placeholder}
        error={fields.licenseEmirate.error}
        default={fields.licenseEmirate.value}
        disabled={props.disabled}
        optionLabel="name"
        optionValue="name"
        className="form-control-select"
      />
    );
  };

  const renderLicenseTypeInput = () => {
    return (
      <SelectInput
        api="entity/v1/license-type/all"
        label={fields.licenseTypeId.label}
        onChange={(value) => changeData('licenseTypeId', value)}
        placeholder={fields.licenseTypeId.placeholder}
        error={fields.licenseTypeId.error}
        default={fields.licenseTypeId.value}
        disabled={props.disabled}
        optionLabel="name"
        optionValue="_id"
        className="form-control-select"
      />
    );
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-x-4 lg:gap-x-10 gap-y-5 lg:gap-y-6 px-4 lg:px-6 py-6 rounded-md border mb-3">
        {props.showRemove && (
          <div className="col-span-2 ltr:text-right rtl:text-left mb-2">
            <button
              type="button"
              onClick={() => props.onDelete && props.onDelete(props.index)}
              className="text-primary text-sm underline"
            >
              <i className="icon-close font-xxs"></i>Remove this License
            </button>
          </div>
        )}
        <div className="col-span-2 md:col-span-1">
          {renderLicenseTypeInput()}
        </div>
        <div className="col-span-2 md:col-span-1">{renderEmirateInput()}</div>
        <div className="col-span-2 md:col-span-1">
          {renderLicenceIssueDateInput()}
        </div>
        <div className="col-span-2 md:col-span-1">
          {renderLicenceExpiryDateInput()}
        </div>
      </div>
    </>
  );
};

export default UAELicenseTypeForm;
