import React, { Component } from 'react';

import './PaymentOption.scss';

interface CustomProps {
  label: string;
  selected?: boolean;
  id: string;
  icon: string;
  disabled?: boolean;
  primarySubText?: boolean;
  onSelect?: () => void;
}

class PaymentOption extends Component<CustomProps> {
  static defaultProps: Partial<CustomProps> = {
    selected: false,
    id: 'pay-online',
    disabled: false,
  };
  renderInput() {
    return (
      <input
        type="radio"
        id={this.props.id}
        name="payment"
        disabled={this.props.disabled}
        checked={this.props.selected}
        value={'payment'}
      />
    );
  }

  renderLabelText = () => {
    return <>{this.props.label}</>;
  };

  // eslint-disable-next-line class-methods-use-this
  renderLabelIcon() {
    return (
      <span className="px-2.5 h-8 inline-flex items-center">
        {this.props.icon === 'ecodrive' ? (
          <svg
            width="26"
            height="21"
            viewBox="0 0 26 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.33398 9.16667C6.33398 8.45942 6.61494 7.78115 7.11503 7.28105C7.61513 6.78095 8.29341 6.5 9.00065 6.5H22.334C23.0412 6.5 23.7195 6.78095 24.2196 7.28105C24.7197 7.78115 25.0007 8.45942 25.0007 9.16667V17.1667C25.0007 17.8739 24.7197 18.5522 24.2196 19.0523C23.7195 19.5524 23.0412 19.8333 22.334 19.8333H9.00065C8.29341 19.8333 7.61513 19.5524 7.11503 19.0523C6.61494 18.5522 6.33398 17.8739 6.33398 17.1667V9.16667Z"
              stroke="#666666"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13 13.1667C13 13.8739 13.281 14.5522 13.781 15.0523C14.2811 15.5524 14.9594 15.8333 15.6667 15.8333C16.3739 15.8333 17.0522 15.5524 17.5523 15.0523C18.0524 14.5522 18.3333 13.8739 18.3333 13.1667C18.3333 12.4594 18.0524 11.7811 17.5523 11.281C17.0522 10.781 16.3739 10.5 15.6667 10.5C14.9594 10.5 14.2811 10.781 13.781 11.281C13.281 11.7811 13 12.4594 13 13.1667Z"
              stroke="#666666"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M19.6667 6.5013V3.83464C19.6667 3.12739 19.3857 2.44911 18.8856 1.94902C18.3855 1.44892 17.7072 1.16797 17 1.16797H3.66667C2.95942 1.16797 2.28115 1.44892 1.78105 1.94902C1.28095 2.44911 1 3.12739 1 3.83464V11.8346C1 12.5419 1.28095 13.2202 1.78105 13.7203C2.28115 14.2203 2.95942 14.5013 3.66667 14.5013H6.33333"
              stroke="#666666"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        ) : (
          <svg
            width="22"
            height="20"
            viewBox="0 0 22 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 10.6621H18.094"
              stroke="currentColor"
              stroke-width="1.30719"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M18.094 8.32094V15.5105C18.0638 18.3762 17.2795 19.1002 14.2931 19.1002H4.8009C1.7642 19.1002 1 18.3461 1 15.3496V8.32094C1 5.60601 1.63348 4.7312 4.01659 4.59042C4.25792 4.58037 4.51936 4.57031 4.8009 4.57031H14.2931C17.3298 4.57031 18.094 5.32446 18.094 8.32094Z"
              stroke="currentColor"
              stroke-width="1.30719"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.1106 4.75063V11.7793C21.1106 14.4942 20.4771 15.369 18.094 15.5098V8.32026C18.094 5.32378 17.3298 4.56963 14.2931 4.56963H4.80092C4.51937 4.56963 4.25793 4.57969 4.0166 4.58974C4.04677 1.72398 4.83108 1 7.8175 1H17.3097C20.3464 1 21.1106 1.75415 21.1106 4.75063Z"
              stroke="currentColor"
              stroke-width="1.30719"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.26758 15.8926H5.99709M8.14892 15.8926H11.6079"
              stroke="currentColor"
              stroke-width="1.30719"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
      </span>
    );
  }

  renderLabel() {
    return (
      <label
        className={`block border rounded-[10px] w-full py-4 before:!top-[22px] ltr:before:!left-4 rtl:before:!right-4 lg:ltr:before:!left-7 lg:rtl:before:!right-4 ltr:!pl-10 rtl:!pr-10 lg:ltr:!pl-14 lg:rtl:!pr-14 ltr:pr-4 rtl:pl-4 ${
          this.props.selected
            ? 'bg-[#F2F2F2] border-[#F2F2F2] text-black'
            : 'border-[#CACACA] text-muted'
        }`}
        htmlFor={this.props.id}
        onClick={(e) => {
          e.preventDefault();
          if (this.props.onSelect) {
            this.props.onSelect();
          }
        }}
      >
        <div className="flex items-center text-base font-medium">
          {this.renderLabelIcon()}
          {this.renderLabelText()}
        </div>
      </label>
    );
  }

  render() {
    return (
      <div className="custom-radio payment-method">
        {this.renderInput()}
        {this.renderLabel()}
      </div>
    );
  }
}

// PaymentOption.propTypes = {
//   label: PropTypes.string.isRequired,
//   selected: PropTypes.bool,
//   id: PropTypes.string,
//   icon: PropTypes.string.isRequired,
//   disabled: PropTypes.bool,
// };

export default PaymentOption;
