import React, { Component, useEffect } from 'react';
// import PropTypes from 'prop-types';

import StepForm from './StepForm';
import StepVisual from './StepVisual';
import StepHeader from './StepHeader';

import './Step.scss';
import { Link } from 'react-router-dom';
import Header from '../header';
import { RouteKeys } from '../route-keys';
import Footer from '../footer';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Step: React.SFC = () => {
  const selectedLanguage = useSelector(
    (state: any) => state.language.selectedLanguage
  );
  useEffect(() => {
    document.body.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className="bg-white py-12 xl:pt-32 lg:pb-0">
        <div className="lg:py-16 container">
          <div className="text-center">
            <h1 className="text-[22px] md:text-[32px] leading-tight font-bold">
              {t('sign_up.register_now')}
            </h1>
            <p className="text-muted text-sm pt-1 lg:pt-5">
              {t('sign_up.have_account')}
              <Link to={`/${selectedLanguage}${RouteKeys.Login}`}>
                <a className="font-bold text-black ml-1">
                  {t('sign_up.login')}
                </a>
              </Link>
            </p>
          </div>
          <StepForm />
        </div>
      </div>
      <Footer avoidFooterResponsive />
    </>
  );
};

// Step.propTypes = {};

// Step.defaultProps = {};

export default Step;
