import React from 'react';

export const ScheduleIcon = (props: any) => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      stroke={'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="20.9111" cy="20" r="20" fill="#EFEFEF" stroke="none" />
      <path
        d="M12.9111 15C12.9111 14.4696 13.1218 13.9609 13.4969 13.5858C13.872 13.2107 14.3807 13 14.9111 13H26.9111C27.4415 13 27.9502 13.2107 28.3253 13.5858C28.7004 13.9609 28.9111 14.4696 28.9111 15V27C28.9111 27.5304 28.7004 28.0391 28.3253 28.4142C27.9502 28.7893 27.4415 29 26.9111 29H14.9111C14.3807 29 13.872 28.7893 13.4969 28.4142C13.1218 28.0391 12.9111 27.5304 12.9111 27V15Z"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.9111 11V15"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.9111 11V15"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.9111 19H28.9111"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
