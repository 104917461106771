import React from 'react';

export const WarningIcon = (props: any) => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0.750945 13.8301C0.609695 13.8301 0.482841 13.7955 0.370383 13.7265C0.257924 13.6574 0.170459 13.5664 0.107987 13.4535C0.0430008 13.3413 0.00725785 13.2197 0.000757848 13.0889C-0.00574215 12.958 0.0295703 12.8284 0.106695 12.7L7.24065 0.376854C7.31778 0.248493 7.4143 0.153597 7.53022 0.0921669C7.64613 0.0307225 7.76819 0 7.8964 0C8.02461 0 8.14667 0.0307225 8.26259 0.0921669C8.37851 0.153597 8.47503 0.248493 8.55215 0.376854L15.6861 12.7C15.7632 12.8284 15.7985 12.958 15.792 13.0889C15.7855 13.2197 15.7498 13.3413 15.6848 13.4535C15.6223 13.5664 15.5349 13.6574 15.4224 13.7265C15.31 13.7955 15.1831 13.8301 15.0419 13.8301H0.750945ZM1.60474 12.5801H14.1881L7.8964 1.74675L1.60474 12.5801ZM7.8964 11.5865C8.08711 11.5865 8.24697 11.522 8.37597 11.393C8.50497 11.264 8.56947 11.1041 8.56947 10.9134C8.56947 10.7227 8.50497 10.5629 8.37597 10.4339C8.24697 10.3049 8.08711 10.2404 7.8964 10.2404C7.70569 10.2404 7.54584 10.3049 7.41684 10.4339C7.28784 10.5629 7.22334 10.7227 7.22334 10.9134C7.22334 11.1041 7.28784 11.264 7.41684 11.393C7.54584 11.522 7.70569 11.5865 7.8964 11.5865ZM7.89667 9.40702C8.07384 9.40702 8.22225 9.34712 8.3419 9.22733C8.46156 9.10754 8.52138 8.95911 8.52138 8.78204V5.86533C8.52138 5.68825 8.46147 5.53982 8.34163 5.42004C8.22179 5.30025 8.07329 5.24035 7.89613 5.24035C7.71897 5.24035 7.57056 5.30025 7.4509 5.42004C7.33125 5.53982 7.27142 5.68825 7.27142 5.86533V8.78204C7.27142 8.95911 7.33134 9.10754 7.45117 9.22733C7.57102 9.34712 7.71952 9.40702 7.89667 9.40702Z" />
    </svg>
  );
};
