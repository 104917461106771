import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Button from '../../components/button';
import ThankYouImage from '../../assets/images/thankyou-img.png';
import { RouteKeys } from '../route-keys';
// actions
import { actions as authUserActions } from '../../store/reducers/authUser';
// import { useGTagConversionTracker } from '../../hooks/gtag';

const ThankYouPage: React.SFC = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const fetchProfile = (onSuccess?: () => void) =>
    dispatch(authUserActions.fetchProfile(onSuccess));

  const selectedLanguage = useSelector(
    (state: any) => state.language.selectedLanguage
  );

  const goBackHome = async () => {
    setLoading(true);
    await fetchProfile(() => {
      history.replace(`/${selectedLanguage}${RouteKeys.Dashboard}`);
      setLoading(false);
    });
  };

  // eslint-disable-next-line class-methods-use-this
  const renderImage = () => {
    return (
      <div className="Thankyou-img">
        <img src={ThankYouImage} className="img-fluid" alt="thankyou" />
      </div>
    );
  };

  // eslint-disable-next-line class-methods-use-this
  const renderHeader = () => {
    return (
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="80"
          height="84"
          viewBox="0 0 80 84"
          fill="currentColor"
          className="text-secondary mx-auto mb-6 lg:mb-9"
        >
          <rect
            x="4.24023"
            y="5.42188"
            width="73.5817"
            height="73.5817"
            rx="36.7908"
          />
          <path
            d="M32.3318 53.1153C33.5112 54.4965 35.5771 54.6834 36.9868 53.5368L58.4321 36.0885C59.3664 35.3366 59.8268 34.1422 59.6415 32.9582C59.4516 31.7728 58.6444 30.7802 57.5233 30.3541C56.4006 29.9281 55.1359 30.1374 54.2106 30.9013L35.2976 46.2881L28.2089 37.987C27.4361 37.0647 26.2282 36.6297 25.0458 36.8449C23.8648 37.0617 22.8887 37.8943 22.4911 39.029C22.0919 40.1636 22.3341 41.4238 23.1219 42.3297L32.3318 53.1153Z"
            fill="white"
          />
          <path d="M41.0317 79.0004C58.2362 79.0004 73.1431 67.0742 76.9206 50.2898C80.6982 33.5038 72.3373 16.3426 56.7907 8.97137C41.247 1.60163 22.6673 5.98759 12.0626 19.5342C3.3864 30.6142 1.79286 45.6736 7.95771 58.3232C14.1196 70.9759 26.9605 79.0019 41.0317 79.0004ZM17.3276 23.6615C26.3895 12.0792 42.4952 8.73817 55.4154 15.7626C68.3356 22.7855 74.2911 38.117 69.4986 52.0208C64.706 65.9247 50.569 74.3304 36.0658 71.9027C21.561 69.4735 10.934 56.918 10.9325 42.2114C10.9206 35.486 13.1718 28.9519 17.3276 23.6615Z" />
        </svg>
        <h3 className="text-3xl md:text-[40px] mb-3">Congratulations !!</h3>
      </div>
    );
  };

  // eslint-disable-next-line class-methods-use-this
  const renderDescription = () => {
    return (
      <p className="text-base md:text-lg mb-6">
        Your account has been registered successfully
      </p>
    );
  };

  const renderBackButton = () => {
    return (
      <button
        type="button"
        className="btn btn-secondary w-full max-w-[256px] mx-auto"
        onClick={goBackHome}
        disabled={loading}
      >
        {loading ? 'Please wait' : 'Back to Home'}
      </button>
    );
  };

  const renderBody = () => {
    return (
      <div className="bg-[#F8F8F8] py-12 lg:py-32 text-center min-h-screen flex">
        <div className="container lg:flex w-full">
          <div className="bg-white shadow rounded-md w-full flex items-center">
            <div className="max-w-sm mx-auto px-4 py-10">
              {renderHeader()}
              {renderDescription()}
              {renderBackButton()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* <Header /> */}
      {renderBody()}
      {/* <Footer /> */}
    </>
  );
};

export default ThankYouPage;
