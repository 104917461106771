import React from 'react';
import Header from '.';

interface CustomProps {
  innerHeader?: boolean;
}

export const UserHeader = (props: CustomProps) => {
  return <Header headerType="logged-in" innerHeader={props.innerHeader} />;
};
