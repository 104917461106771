// language.ts
// Import necessary dependencies and types

// Define your language-related action types
export const languageTypes = {
  SET_LANGUAGE: 'LANGUAGE/SET_LANGUAGE',
};

// Define your language-related action creators
export const languageActions = {
  setLanguage: (language: 'en' | 'ar') => ({
    type: languageTypes.SET_LANGUAGE,
    payload: language,
  }),
};

// Define your initial language state
export interface LanguageState {
  selectedLanguage: 'en' | 'ar';
}

const initialLanguageState: LanguageState = {
  selectedLanguage: 'en',
};

// Define your language reducer
export const languageReducer = (
  state: LanguageState = initialLanguageState,
  action: any
): LanguageState => {
  switch (action.type) {
    case languageTypes.SET_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.payload,
      };
    // Add other cases as needed

    default:
      return state;
  }
};
