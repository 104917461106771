import React from 'react';
import { LogoutButtonProps } from './props';
import Button from '../../button';
import { useDispatch, useSelector } from 'react-redux';

import { actions as userActions } from '../../../store/reducers/authUser';
import { actions as commonActions } from '../../../store/reducers/common';
import { useHistory } from 'react-router';
import { RouteKeys } from '../../../containers/route-keys';
import { useTranslation } from 'react-i18next';

const LogoutButton: React.SFC<LogoutButtonProps> = (
  props: LogoutButtonProps
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedLanguage = useSelector(
    (state: any) => state.language.selectedLanguage
  );

  const logout = () => {
    dispatch(userActions.logout());
    dispatch(commonActions.clearData());
    // history.replace(RouteKeys.Login);
    history.replace(`/${selectedLanguage}${RouteKeys.Dashboard}`);
  };

  return (
    <div className="cursor-pointer flex items-center" onClick={logout}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 21 21"
        fill="none"
        stroke={'currentColor'}
        xmlns="http://www.w3.org/2000/svg"
        className="rtl:rotate-180"
      >
        <path
          d="M1.43972 9.55225L1.43972 7.41101C1.43972 3.1759 3.13566 1.47997 7.37076 1.47996L7.49393 1.47996C11.3027 1.47996 13.0555 2.85377 13.3682 6.20774"
          stroke-width="1.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.377 14.7064C13.0832 18.1173 11.3304 19.51 7.49327 19.51L7.3701 19.51C3.135 19.51 1.43906 17.8141 1.43906 13.579"
          stroke-width="1.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.59891 10.4998L18.3809 10.4998"
          stroke-width="1.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.15 13.3501L19.5 10.0001L16.15 6.6501"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span className="ltr:ml-2.5 rtl:mr-2.5">
        {t('header.Logout')}
      </span>
    </div>
  );
};

export default LogoutButton;
