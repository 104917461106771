import React from 'react';

export const LinkedinIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="currentColor"
      {...props}
    >
      <g clip-path="url(#clip0_1424_7888)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.956 0.5C13.9001 0.5 14.6665 1.26645 14.6665 2.21046V12.7895C14.6665 13.7336 13.9001 14.5 12.956 14.5H2.37696C1.43295 14.5 0.666504 13.7336 0.666504 12.7895V2.21046C0.666504 1.26645 1.43292 0.5 2.37696 0.5L12.956 0.5ZM5.05411 12.074V5.90206H3.00223V12.074H5.05411ZM12.4517 12.074V8.53466C12.4517 6.63884 11.4394 5.75692 10.0897 5.75692C9.00129 5.75692 8.51375 6.3555 8.24078 6.77591V5.90206H6.18942C6.21663 6.48123 6.18942 12.074 6.18942 12.074H8.24075V8.62716C8.24075 8.44265 8.25404 8.25824 8.3084 8.12636C8.45644 7.75791 8.79421 7.37621 9.36097 7.37621C10.1029 7.37621 10.4002 7.94242 10.4002 8.77176V12.074H12.4517ZM4.04204 2.92599C3.34001 2.92599 2.88135 3.38753 2.88135 3.99245C2.88135 4.58466 3.32607 5.05891 4.01488 5.05891H4.02812C4.74357 5.05891 5.18891 4.58466 5.18891 3.99245C5.17565 3.38838 4.74482 2.9273 4.04204 2.92599Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_1424_7888">
          <rect width="14" height="14" transform="translate(0.666504 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
