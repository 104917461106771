import React from 'react';

export const ArrowUturn = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      {...props}
    >
      <g clip-path="url(#clip0_715_10716)">
        <path
          d="M5 15L5.00001 4.74999C5.00001 2.67893 6.67894 1 8.75 1V1C10.8211 1 12.5 2.67893 12.5 4.74999L12.5 16.5"
          stroke="#263238"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1 13L5 17L9 13"
          stroke="#263238"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_715_10716">
          <rect
            width="18"
            height="14"
            fill="white"
            transform="matrix(1.19249e-08 1 1 -1.19249e-08 0 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
