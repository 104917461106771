import React from 'react';

export const FBIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="16"
      viewBox="0 0 7 16"
      fill="currentColor"
      {...props}
    >
      <path d="M4.375 5.65625V3.78125C4.375 3.26375 4.767 2.84375 5.25 2.84375H6.125V0.5H4.375C2.92513 0.5 1.75 1.75906 1.75 3.3125V5.65625H0V8H1.75V15.5H4.375V8H6.125L7 5.65625H4.375Z" />
    </svg>
  );
};
