import React from 'react';

export const TwitterIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="currentColor"
      {...props}
    >
      <path d="M8.22234 6.51793L13.0004 1.08331H11.8683L7.71764 5.80113L4.40497 1.08331H0.583313L5.59385 8.21812L0.583313 13.9166H1.7154L6.09586 8.93331L9.59499 13.9166H13.4166L8.22234 6.51793ZM6.67128 8.28075L6.16284 7.56984L2.12366 1.91842H3.86277L7.12347 6.48099L7.62978 7.1919L11.8677 13.1222H10.1286L6.67128 8.28075Z" />
    </svg>
  );
};
