import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { AccordionBlockCustomeProps } from './props';
import {
  CourseTypeIcon,
  DropdownIcon,
  PaymentMethodIcon,
  PaymentPlanIcon,
  PersonalInfoIcon,
  RoundTickIcon,
} from '../icons';

const AccordionBlock = (props: AccordionBlockCustomeProps) => {
  const ref = useRef(null);

  const {
    children,
    isCurrent,
    isTouched,
    title,
    icon,
    actionText,
    summaries,
    gotoNextBlock,
    addSummary,
    overrideTick,
    showTick,
    suppressParentProps,
    gotoPreviousBlock,
    showAll,
    upDownArrow,
    headingClassName,
  } = props;

  const parentProps = {
    gotoNextBlock,
    gotoPreviousBlock,
    addSummary,
  };

  function handleClick() {
    if (props.openBlock) props.openBlock(ref);
  }

  function renderActionText() {
    const text = actionText || '';
    const summaryList = summaries || {};
    if (!isCurrent && Object.keys(summaryList).length) {
      return (
        <>
          {Object.keys(summaryList).map((summaryKey) => {
            return (
              <>
                {summaryList[summaryKey].label}
                <span className="text-black font-medium mx-1">
                  {summaryList[summaryKey].value}
                </span>
              </>
            );
          })}
        </>
      );
    }
    return <span>{text}</span>;
  }

  const borderClasses = `${props.noBorder ? '' : 'border border-[#ECEBEB]'}`;

  const tickRender = overrideTick
    ? showTick
      ? 'active'
      : ''
    : isCurrent
    ? ''
    : isTouched
    ? 'active'
    : '';

  const touchedClass = isTouched ? 'active' : '';
  const classes = props.className ? props.className : '';

  const renderIcon = (type: string) => {
    switch (type) {
      case 'PersonalDetailIcon':
        return <PersonalInfoIcon />;
      case 'CourseTypeIcon':
        return <CourseTypeIcon />;
      case 'PaymentPlanIcon':
        return <PaymentPlanIcon />;
      case 'PaymentMethodIcon':
        return <PaymentMethodIcon />;
    }
  };

  return (
    <div
      ref={ref}
      key={title}
      className={`bg-white rounded-[10px] shadow px-4 py-5 lg:px-6 lg:py-6  mb-5 relative ${touchedClass} ${classes}`}
    >
      <div
        className={`flex -mx-2 ${
          props.headSize ? 'AccordionBlocks__heading--' + props.headSize : ''
        }`}
        role="presentation"
        onClick={handleClick}
      >
        {icon ? (
          <div className="px-2">
            <div className="w-6">{renderIcon(icon)}</div>
          </div>
        ) : (
          <div className="px-2 self-center">
            <div
              className={`w-8 ${
                tickRender === 'active' ? 'text-secondary' : 'text-gray-300'
              }`}
            >
              <RoundTickIcon />
            </div>
          </div>
        )}

        <div className="px-2">
          <h4
            className={`text-lg font-bold ${
              headingClassName ? headingClassName : ''
            }`}
          >
            {title}
          </h4>
          {(actionText || Object.keys(summaries || {}).length > 0) && (
            <p className="text-xs lg:text-sm text-muted font-medium">
              {renderActionText()}
            </p>
          )}
        </div>
        <div className="px-2 py-2 ltr:ml-auto rtl:mr-auto self-center">
          {upDownArrow ? (
            <span className="text-muted">
              <DropdownIcon
                className={`duration-500 ${isTouched ? 'rotate-180' : ''}`}
              />
            </span>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              className={`${
                tickRender === 'active' ? 'text-white' : 'text-muted'
              }`}
            >
              <circle
                cx="16"
                cy="16"
                r="16"
                className={`${
                  tickRender === 'active' ? 'fill-secondary' : 'fill-[#F2F2F2]'
                }`}
              />
              <rect
                x="21.667"
                y="11.2363"
                width="2.13333"
                height="11.7333"
                transform="rotate(45 21.667 11.2363)"
                fill="currentColor"
              />
              <rect
                x="11.1074"
                y="14.252"
                width="7.46667"
                height="2.13333"
                transform="rotate(45 11.1074 14.252)"
                fill="currentColor"
              />
            </svg>
          )}
        </div>
      </div>
      <div
        className={`AccordionBlocks__collapse ${
          isCurrent || showAll ? 'AccordionBlocks__collapse--in' : ''
        }`}
      >
        <div
          className={`${
            icon ? 'lg:ltr:pl-11 lg:rtl:pr-11' : 'lg:ltr:pl-12 lg:rtl:pr-12'
          } ${
            props.bodySize ? 'AccordionBlocks__body--' + props.bodySize : ''
          }`}
        >
          {suppressParentProps
            ? children
            : React.cloneElement(children, parentProps)}
        </div>
      </div>
    </div>
  );
};

AccordionBlock.propTypes = {
  showAll: PropTypes.bool,
  isCurrent: PropTypes.bool,
  isTouched: PropTypes.bool,

  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  actionText: PropTypes.string.isRequired,

  children: PropTypes.element.isRequired,
  summaries: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  // events
  openBlock: PropTypes.func,
  gotoNextBlock: PropTypes.func,
  gotoPreviousBlock: PropTypes.func,
  addSummary: PropTypes.func,
};

// many of the props are coming from parent and is required for proper functioning
// but since the child will get rendered before parent, we are giving a default value for them.
AccordionBlock.defaultProps = {
  isCurrent: false,
  isTouched: false,
  icon: undefined,

  summaries: {},
  openBlock: () => {
    return;
  },
  gotoNextBlock: () => {
    return;
  },
  gotoPreviousBlock: () => {
    return;
  },
  addSummary: () => {
    return;
  },
};

export default AccordionBlock;
