import React from 'react';

export const MockTestIcon = (props: any) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#EFEFEF" />
      <g clip-path="url(#clip0_2766_46501)">
        <path
          d="M17.875 14.375H16.125C15.6609 14.375 15.2158 14.5594 14.8876 14.8876C14.5594 15.2158 14.375 15.6609 14.375 16.125V26.625C14.375 27.0891 14.5594 27.5342 14.8876 27.8624C15.2158 28.1906 15.6609 28.375 16.125 28.375H24.875C25.3391 28.375 25.7842 28.1906 26.1124 27.8624C26.4406 27.5342 26.625 27.0891 26.625 26.625V16.125C26.625 15.6609 26.4406 15.2158 26.1124 14.8876C25.7842 14.5594 25.3391 14.375 24.875 14.375H23.125"
          stroke="#263238"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.875 14.375C17.875 13.9109 18.0594 13.4658 18.3876 13.1376C18.7158 12.8094 19.1609 12.625 19.625 12.625H21.375C21.8391 12.625 22.2842 12.8094 22.6124 13.1376C22.9406 13.4658 23.125 13.9109 23.125 14.375C23.125 14.8391 22.9406 15.2842 22.6124 15.6124C22.2842 15.9406 21.8391 16.125 21.375 16.125H19.625C19.1609 16.125 18.7158 15.9406 18.3876 15.6124C18.0594 15.2842 17.875 14.8391 17.875 14.375Z"
          stroke="#263238"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};
