import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import {
  registrationStages,
  paymentPlans,
  actions as registrationActions,
} from '../../store/reducers/registration';
import {
  AccordionBlocks,
  AccordionBlock,
} from '../../components/accordion-blocks';
import PersonalDetail from './PersonalDetail';
import CourseType from './CourseType';
import PaymentPlans from './PaymentPlans';
import PaymentMethod from './PaymentMethod';
import Header from '../header';
import Footer from '../footer';

import User, { StudentCourse } from '../../models/user';
import { ContainerLoader, FullPageLoader } from '../../components/loader';
import { State } from '../../store/interfaces';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import '../../styles/personalInformation.scss';
import { useTranslation } from 'react-i18next';

const Step: React.SFC = () => {
  const {
    user,
    currentStage,
    courseType,
    course,
    summary,
    paymentPlan,
    isLoading,
  } = useSelector((state: State) => state.registration);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedLanguage = useSelector(
    (state: any) => state.language.selectedLanguage
  );
  if (
    currentStage === registrationStages.courseSelected &&
    !isLoading &&
    !course &&
    user?.StudentCourse
  ) {
    dispatch(
      registrationActions.setCourse(
        user.StudentCourse,
        user.StudentCourse.courseType
          ? user.StudentCourse.courseType.name.en
          : ''
      )
    );
  }

  const {
    currentIndex,
    personalDetailsSummary,
    courseTypeSummary,
    paymentPlanSummary,
  } = getIndexAndSummaries(
    user,
    currentStage,
    courseType || '',
    course,
    summary,
    paymentPlan || ''
  );

  const history = useHistory();
  React.useEffect(() => {
    document.body.scrollIntoView({ behavior: 'smooth' });
  }, []);

  if (currentIndex === 4) {
    // Registration
    history.replace(`/${selectedLanguage}/signup/thank-you`);
    return <FullPageLoader></FullPageLoader>;
  }

  return (
    <>
      <Header innerHeader />
      <div className="bg-slate-50 py-12 xl:pt-32 lg:pb-0">
        {!user && (
          <ContainerLoader
            height={500}
            text={t('sign_up.fetchingRegistrationDetails')}
          />
        )}
        {user && (
          <div className="lg:py-16 container">
            <div className="text-center pb-9">
              <h1 className="text-[22px] md:text-[32px] leading-tight font-bold">
                {t('sign_up.hi')}, {user.name}
              </h1>
              <p className="text-muted text-sm font-medium pt-1 lg:pt-4">
                {t('sign_up.PleaseFillTheFollowingSteps')}
              </p>
            </div>
            <AccordionBlocks currentIndex={currentIndex}>
              <AccordionBlock
                title={t('sign_up.PersonalDetail')}
                icon={'PersonalDetailIcon'}
                actionText={t('sign_up.PleaseFillYourPersonalDetails')}
                summaries={personalDetailsSummary}
              >
                <PersonalDetail />
              </AccordionBlock>
              <AccordionBlock
                title={t('dashboard.course_type')}
                icon={'CourseTypeIcon'}
                actionText={t('sign_up.ChooseYourCourseType')}
                summaries={courseTypeSummary}
              >
                <CourseType />
              </AccordionBlock>
              <AccordionBlock
                title={t('sign_up.ChoosePaymentPlan')}
                icon={'PaymentPlanIcon'}
                actionText={t('sign_up.ChoosePaymentPlanSubtext')}
                summaries={paymentPlanSummary}
              >
                <PaymentPlans />
              </AccordionBlock>
              <AccordionBlock
                title={t('sign_up.CompletePayment')}
                icon={'PaymentMethodIcon'}
                actionText={t('sign_up.CompletePaymentSubtext')}
              >
                <PaymentMethod />
              </AccordionBlock>
            </AccordionBlocks>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

Step.defaultProps = {
  personalDetailsSummary: {},
  courseTypesSummary: {},
  paymentPlanSummary: {},
  courseType: '',
  course: {},
  summary: {},
  paymentPlan: '',
};

const getPersonalDetailsSummary = (user?: User) =>
  user
    ? {
        licensetype: {
          label: 'Your required license type',
          value: user.studentDetails
            ? user.studentDetails.requiredLicenseCategory
            : '',
        },
        nationality: {
          label: 'and Nationality is',
          value: user.nationality,
        },
      }
    : {};

const getCourseTypeSummary = (courseType: string, course: StudentCourse) =>
  courseType && course && course.amount
    ? {
        coursetype: {
          label: 'Your choose course as',
          value: courseType,
        },
        courseprice: {
          label: 'course price is',
          value:
            course && course.amount
              ? `AED ${course.amount.totalAmount - course.amount.totalDiscount}`
              : '',
        },
      }
    : {};
const getPaymentPlanSummary = (
  fullPaymentSelected: boolean,
  totalAmount: number
) => ({
  paymentplan: {
    label: 'Your choose payment plan as',
    value: fullPaymentSelected ? 'Full Payment' : 'Stage payment',
  },
  paymableamount: {
    label: 'and amount payable is',
    value: `AED ${totalAmount.toFixed(2)}`,
  },
});

const getIndexAndSummaries = (
  user: User | undefined,
  currentStage: string,
  courseType?: string,
  course?: any,
  summary?: any,
  paymentPlan?: string
) => {
  const totalAmount =
    summary.totalAmount + summary.totalTax - summary.totalDiscount;
  const fullPaymentSelected = paymentPlan === paymentPlans.fullPayment;
  switch (currentStage) {
    case registrationStages.profileDetails:
      return {
        currentIndex: 0,
        personalDetailsSummary: {},
        courseTypeSummary: {},
        paymentPlanSummary: {},
      };
    case registrationStages.courseSelection:
      return {
        currentIndex: 1,
        personalDetailsSummary: getPersonalDetailsSummary(user),
        courseTypeSummary: {},
        paymentPlanSummary: {},
      };
    case registrationStages.courseSelected:
      return {
        currentIndex: 2,
        personalDetailsSummary: getPersonalDetailsSummary(user),
        courseTypeSummary: getCourseTypeSummary(courseType as string, course),
        paymentPlanSummary: {},
      };
    case registrationStages.completePayment:
      return {
        currentIndex: 3,
        personalDetailsSummary: getPersonalDetailsSummary(user),
        courseTypeSummary: getCourseTypeSummary(courseType as string, course),
        paymentPlanSummary: getPaymentPlanSummary(
          fullPaymentSelected,
          totalAmount
        ),
      };
    case registrationStages.finished:
      return { currentIndex: 4 };
    default:
      return { currentIndex: 0 };
  }
};

export default Step;
