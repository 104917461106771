import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import './LocationInput.scss';

const dubaiBounds = {
  north: 25.3585607,
  east: 55.5650393,
  south: 24.7921359,
  west: 54.8904543,
};

class LocationSearchInput extends React.Component<
  {
    default?: any;
    withIcon?: string;
    noBorder?: string;
    size?: string;
    label: string;
    placeholder?: string;
    gap?: string;
    error?: string;
    setLocation: (value: any) => void;
    handleError: (value: any) => void;
    className?: string;
  },
  {
    address: string;
    formatted_address: string;
    latitude: string;
    longitude: string;
    location: any;
    value: string;
  }
> {
  static defaultProps: Partial<{
    default?: string;
    withIcon?: string;
    noBorder?: string;
    size?: string;
    label: string;
    placeholder?: string;
    gap?: string;
    error?: string;
    setLocation: (value: any) => void;
    handleError: (value: any) => void;
  }> = {
    gap: 'md',
    default: '',
    label: '',
    error: '',
    placeholder: '',
  };
  constructor(props: any) {
    super(props);
    this.state = {
      address: this.props.default ? this.props.default.address : '',
      formatted_address: '',
      latitude: '',
      longitude: '',
      location: {},
      value: this.props.default ? this.props.default : '',
    };
    this.setValue = this.setValue.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  handleError(address: any) {
    this.setState({ address: '' });
  }

  setValue(place: any, addressName: string) {
    const componentForm = {
      locality: 'long_name',
      administrative_area_level_2: 'long_name',
      administrative_area_level_1: 'long_name',
      country: 'long_name',
    };
    const address: any = {};
    /* tslint:disable:prefer-for-of */
    for (let i = 0; i < place.address_components.length; i += 1) {
      const addressType:
        | 'locality'
        | 'administrative_area_level_2'
        | 'administrative_area_level_1'
        | 'country' = place.address_components[i].types[0];
      if (componentForm[addressType]) {
        const val = place.address_components[i][componentForm[addressType]];
        address[addressType] = val;
      }
    }
    const addressLocation = {
      address: addressName,
      country: address.country,
      state: address.administrative_area_level_1,
      city: address.administrative_area_level_2,
      street: address.locality,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    };

    this.setState({
      formatted_address: addressName,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      location: addressLocation,
      value: place.formatted_address,
    });

    this.handleChange(addressName);
    this.props.setLocation({
      formatted_address: addressName,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      location: addressLocation,
      value: place.formatted_address,
    });
  }

  handleChange = (address: string) => {
    this.setState({ address });
  };

  handleSelect = (address: string) => {
    geocodeByAddress(address)
      .then((results: any) => {
        this.setValue(results[0], address);
      })

      .catch((error: any) => {
        return;
      });
  };

  render() {
    const extraClassesInputs = [];
    let extraClassesInput = '';
    if (this.props.size === 'lg') extraClassesInputs.push('Input__field--lg');
    if (this.props.noBorder) extraClassesInputs.push('Input__field--noborder');
    if (this.props.withIcon) extraClassesInputs.push('Input__field--icon');
    extraClassesInput = extraClassesInputs.join(' ');
    const classNames = `Input__field ${extraClassesInput}`;
    const options = {
      bounds: dubaiBounds,
      // types: ['geocode'],
      componentRestrictions: {
        country: 'AE',
      },
      strictBounds: true,
    };
    const error = this.props.error;

    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={options}
        onError={this.handleError}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            {/* <div className={`Input Input--gap-md`}> */}
            <div className={`relative`}>
              {this.props.label && (
                // <label className="Input__label Input__label--full">
                <label className="block pb-1 text-sm font-medium">
                  {this.props.label}
                </label>
              )}
              <input
                {...getInputProps({
                  placeholder: this.props.placeholder
                    ? this.props.placeholder
                    : 'Enter location...',
                  // className: 'location-search-input',
                  className: classNames,
                })}
                className={this.props.className}
              />
              {suggestions.length > 0 && (
                <div className="autocomplete-dropdown-container text-sm">
                  {loading && <div>Loading...</div>}
                  {suggestions &&
                    suggestions.map((suggestion) => {
                      // eslint-disable-next-line no-use-before-define
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  {!suggestions && (
                    <div>
                      <span>No results</span>
                    </div>
                  )}
                </div>
              )}
              {error && <span className="Input__error">{error}</span>}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default LocationSearchInput;
