import {
  SubmitAnswersRequestModel,
  SubTopicVideoProgressRequest,
} from './../../api/models/lecture';
import {
  TheoryLecture,
  Answer,
  Question,
  Topic,
  Lesson,
  SubTopic,
} from '../../models/theory-lecture';
import { Dispatch } from 'redux';

import api from '../../api';
// import { toastr } from 'react-redux-toastr';
// import { State } from '../interfaces';
import { types as commontypes } from './common';
import { string } from 'prop-types';
import { ErrorResponse } from './error';

export const types = {
  FETCH_LECTURE_REQUEST: 'DASHBOARD/LECTURE/FETCH_LECTURE_REQUEST',
  FETCH_LECTURE_SUCCESS: 'DASHBOARD/LECTURE/FETCH_LECTURE_SUCCESS',
  FETCH_LECTURE_ERROR: 'DASHBOARD/LECTURE/FETCH_LECTURE_ERROR',

  // SubTopic Progress
  SUB_TOPIC_VIDEO_PROGRESS_REQUEST: 'SUB_TOPIC/VIDEO_PROGRESS_REQUEST',
  SUB_TOPIC_VIDEO_PROGRESS_SUCCESS: 'SUB_TOPIC/VIDEO_PROGRESS_SUCCESS',
  SUB_TOPIC_VIDEO_PROGRESS_ERROR: 'SUB_TOPIC/VIDEO_PROGRESS_ERROR',

  // QUIZ
  LOAD_QUIZ: 'DASHBOARD/LECTURE/QUIZ/LOAD_QUIZ',
  QUIZ_ANSWER_SELECTED: 'DASHBOARD/LECTURE/QUIZ/QUIZ_ANSWER_SELECTED',
  QUIZ_SUBMIT_ANSWERS_REQUEST:
    'DASHBOARD/LECTURE/QUIZ/QUIZ_SUBMIT_ANSWERS_REQUEST',
  QUIZ_SUBMIT_ANSWERS_SUCCESS:
    'DASHBOARD/LECTURE/QUIZ/QUIZ_SUBMIT_ANSWERS_SUCCESS',
  QUIZ_SUBMIT_ANSWERS_ERROR: 'DASHBOARD/LECTURE/QUIZ/QUIZ_SUBMIT_ANSWERS_ERROR',
};

export interface QuizState {
  topic?: Topic;
  startDate: Date;
}

export interface LectureState {
  isLoading: boolean;
  isLoaded: boolean;
  errorMessage?: string;
  item?: TheoryLecture;
  videoProgressSyncing: boolean;
  quiz?: QuizState;
}

const initialState: LectureState = {
  isLoading: false,
  isLoaded: false,
  videoProgressSyncing: false,
};

// reducer

const markAnswerSelected = (
  state: LectureState,
  data: {
    lessonId: string;
    topicId: string;
    questionId: string;
    answerId: string;
    selected: boolean;
  }
): LectureState => {
  const { questionId, answerId, selected } = data;
  const answerMap = (ans: Answer) =>
    ans._id === answerId
      ? {
          ...ans,
          isSelected: selected,
        }
      : {
          ...ans,
          isSelected: false,
        };

  const qnaMap = (qa: Question) =>
    qa._id === questionId
      ? {
          ...qa,
          answers: qa.answers.map(answerMap),
        }
      : qa;

  return {
    ...state,
    quiz: {
      ...(state.quiz as QuizState),
      topic: state.quiz
        ? {
            ...(state.quiz.topic as Topic),
            questionAnswers: state.quiz.topic
              ? state.quiz.topic.questionAnswers.map(qnaMap)
              : [],
          }
        : undefined,
    },
  };
};

const updateSubTopicProgress = (
  lecture: TheoryLecture,
  data: SubTopicVideoProgressRequest
): TheoryLecture => {
  const { id, completePercentage, completedTime } = data;
  const subTopicMap = (subTopic: SubTopic): SubTopic =>
    subTopic._id === id
      ? {
          ...subTopic,
          completePercentage,
          isCompleted: completePercentage >= 100,
          completedTime,
        }
      : {
          ...subTopic,
        };

  const topicMap = (topic: Topic): Topic => ({
    ...topic,
    subTopic: topic.subTopic.map(subTopicMap),
  });
  const lessonMap = (lesson: Lesson): Lesson => ({
    ...lesson,
    topics: [],
  });

  return {
    ...lecture,
    lessons: lecture.lessons.map(lessonMap),
  };
};
export default (
  state: LectureState = initialState,
  action: any
): LectureState => {
  switch (action.type) {
    case commontypes.RESET_DATA:
      return { ...initialState };
    case types.FETCH_LECTURE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        errorMessage: undefined,
      };
    case types.FETCH_LECTURE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        item: action.data,
        isLoaded: true,
        errorMessage: undefined,
      };
    case types.FETCH_LECTURE_ERROR:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        errorMessage: action.errorMessage,
      };

    case types.SUB_TOPIC_VIDEO_PROGRESS_REQUEST:
      return {
        ...state,
        videoProgressSyncing: true,
        item: state.item
          ? updateSubTopicProgress(state.item as TheoryLecture, action.data)
          : undefined,
      };

    case types.SUB_TOPIC_VIDEO_PROGRESS_SUCCESS:
      return { ...state, videoProgressSyncing: false, item: action.data };

    case types.SUB_TOPIC_VIDEO_PROGRESS_ERROR:
      return { ...state, videoProgressSyncing: false };

    case types.LOAD_QUIZ:
      if (action.data) {
        action.data.questionAnswers.forEach((qna: Question) => {
          qna.answers.forEach((ans: Answer) => {
            ans.isSelected = false;
          });
        });
      }
      return { ...state, quiz: { topic: action.data, startDate: new Date() } };
    case types.QUIZ_SUBMIT_ANSWERS_REQUEST:
      return { ...state, isLoading: true };
    case types.QUIZ_SUBMIT_ANSWERS_SUCCESS:
      return { ...state, isLoading: false /* item: action.data */ };
    case types.QUIZ_SUBMIT_ANSWERS_ERROR:
      return { ...state, isLoading: false };
    case types.QUIZ_ANSWER_SELECTED:
      return markAnswerSelected(state, action.data);
    default:
      return state;
  }
};

// action creators & async actions
export const actions = {
  getCurrentLecture: (
    licenseTypeId: string,
    language?: string,
    lessonId?: string,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({ type: types.FETCH_LECTURE_REQUEST });
    try {
      const response = await api.lecture.getCurrentLecture(
        licenseTypeId,
        language,
        lessonId
      );
      dispatch({
        type: types.FETCH_LECTURE_SUCCESS,
        data: response.data.lecture,
      });
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      const typedError = error as ErrorResponse;

      dispatch({
        type: types.FETCH_LECTURE_ERROR,
        data:
          typedError && typedError.response && typedError.response.data
            ? typedError.response.data.errors
            : {},
        errorMessage:
          typedError &&
          typedError.response &&
          typedError.response.data &&
          typedError.response.data.message
            ? typedError.response.data.message
            : 'Unable load documents!',
      });
      // throw error;
    }
  },

  reportSubTopicVideoProgress: (data: SubTopicVideoProgressRequest) => async (
    dispatch: Dispatch
  ) => {
    dispatch({ type: types.SUB_TOPIC_VIDEO_PROGRESS_REQUEST, data });
    try {
      const response = await api.lecture.reportSubTopicVideoProgress(data);
      dispatch({
        type: types.SUB_TOPIC_VIDEO_PROGRESS_SUCCESS,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.SUB_TOPIC_VIDEO_PROGRESS_ERROR,
        // data: error.response.data.errors,
      });
      throw error;
    }
  },

  answerSelected: (data: {
    lessonId: string;
    topicId: string;
    questionId: string;
    answerId: string;
    selected: boolean;
  }) => async (dispatch: Dispatch) => {
    dispatch({
      type: types.QUIZ_ANSWER_SELECTED,
      data,
    });
  },
  loadQuiz: (lesson: Lesson, topicId: string) => async (dispatch: any) => {
    const topic: Topic | undefined = lesson.topics?.find(
      (tp) => tp._id === topicId
    );
    dispatch({
      type: types.LOAD_QUIZ,
      data: topic,
    });
  },
  closeQuiz: () => async (dispatch: any) => {
    dispatch({
      type: types.LOAD_QUIZ,
      data: undefined,
    });
  },
  submitAnswers: (
    data: {
      lessonId: string;
      topicId: string;
      data: SubmitAnswersRequestModel;
    },
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void
  ) => async (dispatch: Dispatch) => {
    dispatch({
      type: types.QUIZ_SUBMIT_ANSWERS_REQUEST,
      data,
    });

    try {
      const response = await api.lecture.submitAnswers(
        data.lessonId,
        data.topicId,
        data.data
      );
      dispatch({
        type: types.QUIZ_SUBMIT_ANSWERS_SUCCESS,
        data: response.data,
      });
      if (onSuccess) onSuccess();
    } catch (error) {
      const typedError = error as ErrorResponse;

      dispatch({
        type: types.QUIZ_SUBMIT_ANSWERS_ERROR,
        data: typedError.response.data.errors,
      });
      if (onError) onError('Unable to submit answers.');
      // throw error;
    }
  },
};
