import React from 'react';

export const InstaIcon = (props: any) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.49987 3.59888C5.38294 3.59888 3.62891 5.32267 3.62891 7.46984C3.62891 9.61702 5.3527 11.3408 7.49987 11.3408C9.64705 11.3408 11.3708 9.58678 11.3708 7.46984C11.3708 5.35291 9.61681 3.59888 7.49987 3.59888ZM7.49987 9.94968C6.13899 9.94968 5.02004 8.83073 5.02004 7.46984C5.02004 6.10896 6.13899 4.99001 7.49987 4.99001C8.86076 4.99001 9.97971 6.10896 9.97971 7.46984C9.97971 8.83073 8.86076 9.94968 7.49987 9.94968Z" />
      <path d="M11.5223 4.38514C12.0066 4.38514 12.3993 3.99248 12.3993 3.50812C12.3993 3.02376 12.0066 2.6311 11.5223 2.6311C11.0379 2.6311 10.6453 3.02376 10.6453 3.50812C10.6453 3.99248 11.0379 4.38514 11.5223 4.38514Z" />
      <path d="M13.7903 1.23992C13.004 0.423387 11.8851 0 10.6149 0H4.38508C1.75403 0 0 1.75403 0 4.38508V10.5847C0 11.8851 0.423387 13.004 1.27016 13.8206C2.08669 14.6069 3.1754 15 4.41532 15H10.5847C11.8851 15 12.9738 14.5766 13.7601 13.8206C14.5766 13.0343 15 11.9153 15 10.6149V4.38508C15 3.11492 14.5766 2.02621 13.7903 1.23992ZM13.6694 10.6149C13.6694 11.5524 13.3367 12.3085 12.7923 12.8226C12.248 13.3367 11.4919 13.6089 10.5847 13.6089H4.41532C3.50806 13.6089 2.75202 13.3367 2.20766 12.8226C1.66331 12.2782 1.39113 11.5222 1.39113 10.5847V4.38508C1.39113 3.47782 1.66331 2.72177 2.20766 2.17742C2.72177 1.66331 3.50806 1.39113 4.41532 1.39113H10.6452C11.5524 1.39113 12.3085 1.66331 12.8528 2.20766C13.3669 2.75202 13.6694 3.50806 13.6694 4.38508V10.6149Z" />
    </svg>
  );
};
