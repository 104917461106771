import React, { Component } from 'react';
// import PropTypes from 'prop-types';

import './CourseTab.scss';
import Button from '../../button';
import { formatMultiLanguageText } from '../../../utils/multi-language-utils';
import { StudentCourse } from '../../../models/user';
import CoursePlan from '../../../components/on-boarding/course-plan';

interface CustomProps {
  courses: StudentCourse[];
  selectedId?: string;
  onSelect: (courseType: any, course: any) => void;
}

interface CustomState {
  isMore: boolean;
  activeIndex?: number;
  isExpanded: boolean;
  disableSelect: boolean;
  selectedDetails?: StudentCourse;
}

class CourseTab extends Component<CustomProps, CustomState> {
  constructor(props: CustomProps) {
    super(props);
    this.state = {
      isMore: false,
      isExpanded: false,
      disableSelect: false,
    };
  }

  onChangeExpanded() {
    this.setState((prevState) => {
      return {
        isExpanded: !prevState.isExpanded,
      };
    });
  }

  render() {
    const { courses } = this.props;

    let selectedIndex = 1;
    if (this.props.selectedId) {
      const selectedDetails = this.props.courses?.filter(
        (x) => x.courseTypeId === this.props.selectedId?.toString()
      );
      if (selectedDetails[0]?.courseType?.code === 'R') {
        selectedIndex = 1;
      } else if (
        selectedDetails[0]?.courseType?.code === 'F' ||
        selectedDetails[0]?.courseType?.code === 'PREF'
      ) {
        selectedIndex = 2;
      } else {
        if (
          selectedDetails[0]?.course?.isLumpSumCourse &&
          !selectedDetails[0]?.course?.isVipCourse
        ) {
          selectedIndex = 3;
        } else if (
          selectedDetails[0]?.course?.isLumpSumCourse &&
          selectedDetails[0]?.course?.isVipCourse
        ) {
          selectedIndex = 4;
        }
      }
    }

    const activeTab = this.state.activeIndex ?? selectedIndex;
    const regularCourses = courses?.filter((x) => x.courseType?.code === 'R');
    const flexiCourses = courses?.filter(
      (x) => x.courseType?.code === 'F' || x.courseType?.code === 'PREF'
    );
    const lumpsumCourses = courses?.filter(
      (x) => x.course?.isLumpSumCourse && !x.course?.isVipCourse
    );
    const vipCourses = courses?.filter(
      (x) => x.course?.isLumpSumCourse && x.course?.isVipCourse
    );

    return (
      <div>
        <div className="flex -mx-1.5 mb-6">
          {courses && regularCourses?.length > 0 && (
            <div className="px-1.5">
              <button
                className={`px-6 py-2 rounded-3xl text-sm ${
                  activeTab === 1
                    ? 'bg-primary text-white'
                    : 'bg-[#F2F2F2] text-muted'
                }`}
                onClick={() => this.setState({ activeIndex: 1 })}
              >
                Regular
              </button>
            </div>
          )}
          {courses && flexiCourses?.length > 0 && (
            <div className="px-1.5">
              <button
                className={`px-6 py-2 rounded-3xl text-sm ${
                  activeTab === 2
                    ? 'bg-primary text-white'
                    : 'bg-[#F2F2F2] text-muted'
                }`}
                onClick={() => this.setState({ activeIndex: 2 })}
              >
                Flexi
              </button>
            </div>
          )}
          {courses && lumpsumCourses?.length > 0 && (
            <div className="px-1.5">
              <button
                className={`px-6 py-2 rounded-3xl text-sm ${
                  activeTab === 3
                    ? 'bg-primary text-white'
                    : 'bg-[#F2F2F2] text-muted'
                }`}
                onClick={() => this.setState({ activeIndex: 3 })}
              >
                Lumpsum
              </button>
            </div>
          )}
          {courses && vipCourses?.length > 0 && (
            <div className="px-1.5">
              <button
                className={`px-6 py-2 rounded-3xl text-sm ${
                  activeTab === 4
                    ? 'bg-primary text-white'
                    : 'bg-[#F2F2F2] text-muted'
                }`}
                onClick={() => this.setState({ activeIndex: 4 })}
              >
                VIP
              </button>
            </div>
          )}
        </div>
        <div>
          {courses && regularCourses?.length > 0 && (
            <div className={activeTab === 1 ? 'block' : 'hidden'}>
              {regularCourses.map((course, index) => {
                return (
                  <CoursePlan
                    key={`${index}`}
                    course={course}
                    onSelect={() => {
                      this.props.onSelect(course?.courseType?.name?.en, course);
                    }}
                    isExpanded={this.state.isExpanded}
                    onChangeExpanded={this.onChangeExpanded}
                    selectedId={this.props.selectedId}
                    disableSelect={this.state.disableSelect}
                  />
                );
              })}
            </div>
          )}
          {courses && flexiCourses?.length > 0 && (
            <div className={activeTab === 2 ? 'block' : 'hidden'}>
              {flexiCourses.map((course, index) => {
                return (
                  <CoursePlan
                    key={`${index}`}
                    course={course}
                    onSelect={() => {
                      this.props.onSelect(course?.courseType?.name?.en, course);
                    }}
                    isExpanded={this.state.isExpanded}
                    onChangeExpanded={this.onChangeExpanded}
                    selectedId={this.props.selectedId}
                    disableSelect={this.state.disableSelect}
                  />
                );
              })}
            </div>
          )}
          {courses && courses?.length > 0 && (
            <div className={activeTab === 3 ? 'block' : 'hidden'}>
              {lumpsumCourses.map((course, index) => {
                return (
                  <CoursePlan
                    key={`${index}`}
                    course={course}
                    onSelect={() => {
                      this.props.onSelect(course?.courseType?.name?.en, course);
                    }}
                    isExpanded={this.state.isExpanded}
                    onChangeExpanded={this.onChangeExpanded}
                    selectedId={this.props.selectedId}
                    disableSelect={this.state.disableSelect}
                  />
                );
              })}
            </div>
          )}
          {courses && courses?.length > 0 && (
            <div className={activeTab === 4 ? 'block' : 'hidden'}>
              {vipCourses.map((course, index) => {
                return (
                  <CoursePlan
                    key={`${index}`}
                    course={course}
                    onSelect={() => {
                      this.props.onSelect(course?.courseType?.name?.en, course);
                    }}
                    isExpanded={this.state.isExpanded}
                    onChangeExpanded={this.onChangeExpanded}
                    selectedId={this.props.selectedId}
                    disableSelect={this.state.disableSelect}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CourseTab;
