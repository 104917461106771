import React from 'react';

export const YoutubeIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="15"
      viewBox="0 0 19 15"
      fill="currentColor"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.9856 2.1791C17.2506 2.45223 17.4387 2.79044 17.5311 3.15957C17.7779 4.52744 17.8976 5.9152 17.8889 7.30512C17.8938 8.67427 17.7741 10.041 17.5311 11.3885C17.4387 11.7576 17.2506 12.0958 16.9856 12.3689C16.7207 12.642 16.3884 12.8404 16.0222 12.944C14.6845 13.3018 9.33334 13.3018 9.33334 13.3018C9.33334 13.3018 3.98223 13.3018 2.64446 12.944C2.28576 12.8459 1.95844 12.6569 1.69412 12.3953C1.4298 12.1337 1.23742 11.8083 1.13557 11.4507C0.88883 10.0828 0.769061 8.69505 0.777788 7.30512C0.770972 5.92558 0.890733 4.54833 1.13557 3.19068C1.22796 2.82155 1.41612 2.48334 1.68106 2.21022C1.94599 1.93709 2.27831 1.73871 2.64446 1.63512C3.98223 1.27734 9.33334 1.27734 9.33334 1.27734C9.33334 1.27734 14.6845 1.27734 16.0222 1.60401C16.3884 1.7076 16.7207 1.90598 16.9856 2.1791ZM12.0556 7.30511L7.58337 9.84845V4.76178L12.0556 7.30511Z"
      />
    </svg>
  );
};
