import { loadingBarReducer } from 'react-redux-loading-bar';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { combineReducers } from 'redux';

import authUser from './reducers/authUser';
import scheduleReducer from './reducers/schedule';
import registration from './reducers/registration';
import payment from './reducers/payment';
import dashboardReducer from './reducers/dashboard';
import lectureReducer from './reducers/lecture';
import integratorReducer from './reducers/integrator';
import notification from './reducers/notification';
import { languageReducer } from './reducers/language.store';
import { State } from './interfaces';

const rootReducer = combineReducers<State>({
  authUser,
  registration,
  payment,
  toastr: toastrReducer,
  loadingBar: loadingBarReducer,
  dashboard: dashboardReducer,
  lecture: lectureReducer,
  schedule: scheduleReducer,
  integrator: integratorReducer,
  notification,
  language: languageReducer,
});

export default rootReducer;
