import React from 'react';

export const HamburgerIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="15"
      viewBox="0 0 22 15"
      fill="currentColor"
      {...props}
    >
      <path d="M1.22222 14.6667C0.875926 14.6667 0.585648 14.5495 0.351389 14.3153C0.11713 14.081 0 13.7907 0 13.4444C0 13.0981 0.11713 12.8079 0.351389 12.5736C0.585648 12.3394 0.875926 12.2222 1.22222 12.2222H20.7778C21.1241 12.2222 21.4144 12.3394 21.6486 12.5736C21.8829 12.8079 22 13.0981 22 13.4444C22 13.7907 21.8829 14.081 21.6486 14.3153C21.4144 14.5495 21.1241 14.6667 20.7778 14.6667H1.22222ZM1.22222 8.55556C0.875926 8.55556 0.585648 8.43843 0.351389 8.20417C0.11713 7.96991 0 7.67963 0 7.33333C0 6.98704 0.11713 6.69676 0.351389 6.4625C0.585648 6.22824 0.875926 6.11111 1.22222 6.11111H20.7778C21.1241 6.11111 21.4144 6.22824 21.6486 6.4625C21.8829 6.69676 22 6.98704 22 7.33333C22 7.67963 21.8829 7.96991 21.6486 8.20417C21.4144 8.43843 21.1241 8.55556 20.7778 8.55556H1.22222ZM1.22222 2.44444C0.875926 2.44444 0.585648 2.32732 0.351389 2.09306C0.11713 1.8588 0 1.56852 0 1.22222C0 0.875926 0.11713 0.585648 0.351389 0.351389C0.585648 0.11713 0.875926 0 1.22222 0H20.7778C21.1241 0 21.4144 0.11713 21.6486 0.351389C21.8829 0.585648 22 0.875926 22 1.22222C22 1.56852 21.8829 1.8588 21.6486 2.09306C21.4144 2.32732 21.1241 2.44444 20.7778 2.44444H1.22222Z" />
    </svg>
  );
};
