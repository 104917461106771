/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import './Header.scss';
import LogoImage from '../../assets/images/logo.svg';
import menuImage from '../../assets/images/menu.png';
import { Link, useHistory } from 'react-router-dom';
import {
  CloseIcon,
  DropdownIcon,
  FBIcon,
  HamburgerIcon,
  InstaIcon,
  LinkedinIcon,
  TwitterIcon,
  YoutubeIcon,
} from '../../components/icons';
import NotificationCount from './notification-count';
import { useLanguage } from '../../LanguageContext'; // Import useLanguage hook
import { useSelector } from 'react-redux';

interface CustomState {
  activeClass: string;
  mobDropdown: boolean;
  menuOpen: boolean;
  courseDopdown: boolean;
}

interface CustomProps {
  innerHeader?: boolean;
}

const cmsDomain = process.env.REACT_APP_CMS_DOMAIN;
const appStoreLinks = {
  android: '',
  ios: '',
};

const countryCodeOptions = [
  { value: '+971', label: '+971' },
  { value: '+972', label: '+972' },
];

const startingCodeOptions = [
  { label: '50', value: '50' },
  { label: '52', value: '52' },
  { label: '54', value: '54' },
  { label: '55', value: '55' },
  { label: '56', value: '56' },
  { label: '58', value: '58' },
];

const HeaderCanvas: React.FunctionComponent<CustomProps> = (
  props: CustomProps
) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [courseDopdown, setCourseDropdown] = useState<boolean>(false);
  const [mobExtDropdown, setMobExtDropdown] = useState<boolean>(false);
  const [phone, setPhone] = useState({
    code: countryCodeOptions[0].value,
    startingCode: startingCodeOptions[0].value,
    number: '',
  });

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (!menuOpen && window.innerWidth < 1024) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  };
  const history = useHistory();
  const [hoverLi, setHoverLi] = useState<string>('Car Driving');
  const { t } = useTranslation();
  const selectedLanguage = useSelector(
    (state: any) => state.language.selectedLanguage
  );
  const { setSelectedLanguage } = useLanguage();
  const [lang, setLang] = React.useState<'en' | 'ar'>(selectedLanguage);

  const changeLanguage = (lng: 'en' | 'ar') => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
    setLang(lng);
    // Update the URL to include the selectedLanguage
    const newPath = window.location.pathname.replace(/^\/(en|ar)/, `/${lng}`);
    history.push(newPath);
  };
  useEffect(() => {
    const urlLanguage = window.location.pathname.split('/')[1];
    if (urlLanguage && (urlLanguage === 'en' || urlLanguage === 'ar')) {
      changeLanguage(urlLanguage);
    }
  }, [selectedLanguage]);

  useEffect(() => {
    const dir = lang === 'en' ? 'ltr' : 'rtl';
    const language = lang === 'en' ? 'en' : 'ar';
    const htmlElement = document.querySelector('html');
    if (htmlElement) {
      htmlElement.setAttribute('dir', dir);
      htmlElement.setAttribute('lang', language);
    }
  }, [lang]);

  return (
    <header className="relative hidden lg:block">
      <div className="absolute top-0 z-10 mx-auto left-0 right-0 w-full xl:px-[50px] p-4 bg-white shadow-sm">
        <div className="flex items-center -mx-3">
          <div className="flex lg:w-auto w-full px-3">
            <div className="flex logo">
              <Link to="/" className="cursor-pointer flex">
                <span className="cursor-pointer flex w-32 xl:w-auto">
                  <img src={LogoImage} alt="" />
                </span>
              </Link>
            </div>
          </div>
          <div
            className={`px-4 lg:px-3 lg:py-4 flex-grow text-base lg:text-[13px] xl:text-sm font-medium lg:font-normal h-full lg:h-auto lg:opacity-100 lg:visible lg:scale-100 lg:relative fixed top-0 left-0 lg:w-auto w-full lg:bg-transparent bg-white lg:overflow-visible overflow-auto transition-all duration-500 ease-in-out z-[9995]
            ${
              menuOpen
                ? 'opacity-100 visible scale-100'
                : 'opacity-0 invisible scale-95'
            }`}
          >
            <div className="flex flex-wrap lg:items-center justify-end flex-col lg:flex-row lg:-mx-3.5 xl:-mx-[22px]">
              <div className="lg:hidden flex items-center border-b py-7 text-2xl font-bold leading-none text-primary">
                {t('header.menu')}
                <CloseIcon className="ml-auto" onClick={() => toggleMenu()} />
              </div>
              <div className="lg:px-3.5 xl:px-[22px] lg:order-last border-b lg:border-0">
                <Link to="/">
                  <a className="cursor-pointer flex items-center py-3.5 lg:py-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="18"
                      viewBox="0 0 19 18"
                      fill="currentColor"
                      className="w-[25px] h-[25px] lg:w-[19px] lg:h-[19px]"
                    >
                      <g clipPath="url(#clip0_1249_8076)">
                        <path d="M9.66675 0C12.1519 0 14.4019 1.0075 16.0306 2.6361C17.6592 4.26485 18.6667 6.51485 18.6667 9C18.6667 11.4851 17.6592 13.7351 16.0306 15.3639C14.4019 16.9925 12.1519 18 9.66675 18C7.1816 18 4.9316 16.9925 3.30285 15.3639C1.67425 13.7351 0.666748 11.4851 0.666748 9C0.666748 6.51485 1.67425 4.26485 3.30285 2.6361C4.9316 1.0075 7.1816 0 9.66675 0ZM15.2017 3.46508C13.7853 2.04868 11.8284 1.17246 9.66675 1.17246C7.50512 1.17246 5.54823 2.04868 4.13183 3.46493C2.71543 4.88148 1.83921 6.83837 1.83921 9C1.83921 11.1616 2.71543 13.1185 4.13168 14.5349C5.54808 15.9512 7.50511 16.8274 9.6666 16.8274C11.8281 16.8274 13.7851 15.9512 15.2015 14.5349C16.6178 13.1185 17.494 11.1615 17.494 9C17.494 6.83852 16.6181 4.88148 15.2017 3.46508Z" />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.71744 13.8556L4.71386 13.8311C4.48214 12.2563 5.28028 10.775 6.72276 10.102C6.88742 10.0251 7.06177 9.95626 7.24372 9.89471C7.9581 10.3026 8.78529 10.5358 9.66687 10.5358C10.5485 10.5358 11.3756 10.3026 12.09 9.89471C12.272 9.95626 12.4463 10.0251 12.611 10.102C14.0535 10.775 14.8515 12.2563 14.6199 13.8311L14.6163 13.8556C14.6008 13.8714 14.5853 13.887 14.5698 13.9027C13.2647 15.2077 11.5129 15.9333 9.66702 15.9333C7.82116 15.9333 6.06932 15.2077 4.76423 13.9027C4.74829 13.8872 4.73294 13.8714 4.71744 13.8556Z"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.66672 3.06976C11.4607 3.06976 12.915 4.52402 12.915 6.31802C12.915 8.11203 11.4607 9.56629 9.66672 9.56629C7.87271 9.56629 6.41846 8.11203 6.41846 6.31802C6.41846 4.52416 7.87271 3.06976 9.66672 3.06976Z"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1249_8076">
                          <rect
                            width="18"
                            height="18"
                            transform="translate(0.666748)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="ltr:ml-3 rtl:mr-3 lg:ltr:ml-2 lg:rtl:mr-2">
                      {t('header.student_login')}
                    </span>
                  </a>
                </Link>
              </div>
              <div className="lg:px-3.5 xl:px-[22px]">
                <div className="xl:ltr:pr-6 xl:rtl:pl-6">
                  <ul className="lg:flex lg:-mx-4">
                    <li className="relative lg:border-b-0 border-b border-b-slate-200 lg:px-4">
                      <a
                        className="cursor-pointer block whitespace-nowrap lg:py-0 py-4"
                        onClick={() => setCourseDropdown(!courseDopdown)}
                      >
                        {t('header.all_courses')}
                        <DropdownIcon className="lg:hidden lg:ltr:ml-2 lg:rtl:mr-2 ltr:ml-auto rtl:mr-auto min-w-[12px]" />
                      </a>
                      <div
                        className={`fixed lg:absolute top-0 lg:top-full left-0 lg:ltr:left-4 lg:rtl:right-4 z-10 w-full h-full lg:h-auto bg-white lg:bg-transparent lg:min-w-[764px] transition-all duration-500 ease-in-out lg:pt-2.5 font-normal ${
                          courseDopdown
                            ? 'opacity-100 visible lg:mt-2.5 lg:block'
                            : 'opacity-0 invisible lg:mt-4 lg:block hidden'
                        }`}
                      >
                        <div className="bg-white lg:p-5 pb-4 rounded w-full min-w-[764px] min-h-[383px] lg:shadow">
                          <ul className="relative">
                            <li
                              className="ltr:pl-[254px] rtl:pr-[254px]"
                              onMouseOver={() => setHoverLi('Car Driving')}
                            >
                              <div>
                                <div
                                  className={`absolute top-0 ltr:left-0 rtl:right-0 ${
                                    hoverLi === 'Car Driving'
                                      ? 'block'
                                      : 'hidden'
                                  }`}
                                >
                                  <div className="relative">
                                    <img
                                      src={menuImage}
                                      width="225"
                                      height="344"
                                      alt="Car"
                                    />
                                    <span className="absolute bottom-4 ltr:left-4 rtl:right-4 text-2xl text-white">
                                      {t('header.CarDriving')}
                                    </span>
                                  </div>
                                </div>
                                <a
                                  href={cmsDomain + '/car-driving-course'}
                                  target="_blank"
                                  className="block text-black py-[9px]"
                                  onClick={() => toggleMenu()}
                                >
                                  <p className="text-base font-medium">
                                    {t('header.CarDriving')}
                                  </p>
                                  <p className="text-muted">
                                    {t('header.CarDrivingSubtext')}
                                  </p>
                                </a>
                              </div>
                            </li>
                            <li
                              className="ltr:pl-[254px] rtl:pr-[254px]"
                              onMouseOver={() => setHoverLi('Two Wheeler')}
                            >
                              <div>
                                <div
                                  className={`absolute top-0 ltr:left-0 rtl:right-0 ${
                                    hoverLi === 'Two Wheeler'
                                      ? 'block'
                                      : 'hidden'
                                  }`}
                                >
                                  <div className="relative">
                                    <img
                                      src={menuImage}
                                      width="225"
                                      height="344"
                                      alt="Car"
                                    />
                                    <span className="absolute bottom-4 ltr:left-4 rtl:right-4 text-2xl text-white">
                                      {t('header.TwoWheeler')}
                                    </span>
                                  </div>
                                </div>
                                <a
                                  href={cmsDomain + '/motorcycle-course'}
                                  target="_blank"
                                  className="block text-black py-[9px]"
                                  onClick={() => toggleMenu()}
                                >
                                  <p className="text-base font-medium">
                                    {t('header.TwoWheeler')}
                                  </p>
                                  <p className="text-muted">
                                    {t('header.TwoWheelerSubtext')}
                                  </p>
                                </a>
                              </div>
                            </li>
                            <li
                              className="ltr:pl-[254px] rtl:pr-[254px]"
                              onMouseOver={() => setHoverLi('Heavy Bus')}
                            >
                              <div>
                                <div
                                  className={`absolute top-0 ltr:left-0 rtl:right-0 ${
                                    hoverLi === 'Heavy Bus' ? 'block' : 'hidden'
                                  }`}
                                >
                                  <div className="relative">
                                    <img
                                      src={menuImage}
                                      width="225"
                                      height="344"
                                      alt="Car"
                                    />
                                    <span className="absolute bottom-4 ltr:left-4 rtl:right-4 text-2xl text-white">
                                      {t('header.HeavyBus')}
                                    </span>
                                  </div>
                                </div>
                                <a
                                  href={cmsDomain + '/heavy-bus-course'}
                                  target="_blank"
                                  className="block text-black py-[9px]"
                                  onClick={() => toggleMenu()}
                                >
                                  <p className="text-base font-medium">
                                    {t('header.HeavyBus')}
                                  </p>
                                  <p className="text-muted">
                                    {t('header.HeavyBusSubtext')}
                                  </p>
                                </a>
                              </div>
                            </li>
                            <li
                              className="ltr:pl-[254px] rtl:pr-[254px]"
                              onMouseOver={() => setHoverLi('Heavy Truck')}
                            >
                              <div>
                                <div
                                  className={`absolute top-0 ltr:left-0 rtl:right-0 ${
                                    hoverLi === 'Heavy Truck'
                                      ? 'block'
                                      : 'hidden'
                                  }`}
                                >
                                  <div className="relative">
                                    <img
                                      src={menuImage}
                                      width="225"
                                      height="344"
                                      alt="Car"
                                    />
                                    <span className="absolute bottom-4 ltr:left-4 rtl:right-4 text-2xl text-white">
                                      {t('header.HeavyTruck')}
                                    </span>
                                  </div>
                                </div>
                                <a
                                  href={cmsDomain + '/heavy-truck-course'}
                                  target="_blank"
                                  className="block text-black py-[9px]"
                                  onClick={() => toggleMenu()}
                                >
                                  <p className="text-base font-medium">
                                    {t('header.HeavyTruck')}
                                  </p>
                                  <p className="text-muted">
                                    {t('header.HeavyTruckSubtext')}
                                  </p>
                                </a>
                              </div>
                            </li>
                            <li
                              className="ltr:pl-[254px] rtl:pr-[254px]"
                              onMouseOver={() => setHoverLi('Heavy Forklift')}
                            >
                              <div>
                                <div
                                  className={`absolute top-0 ltr:left-0 rtl:right-0 ${
                                    hoverLi === 'Heavy Forklift'
                                      ? 'block'
                                      : 'hidden'
                                  }`}
                                >
                                  <div className="relative">
                                    <img
                                      src={menuImage}
                                      width="225"
                                      height="344"
                                      alt="Car"
                                    />
                                    <span className="absolute bottom-4 ltr:left-4 rtl:right-4 text-2xl text-white">
                                      {t('header.HeavyForklift')}
                                    </span>
                                  </div>
                                </div>
                                <a
                                  href={cmsDomain + '/forklift-course'}
                                  target="_blank"
                                  className="block text-black py-[9px]"
                                  onClick={() => toggleMenu()}
                                >
                                  <p className="text-base font-medium">
                                    {t('header.HeavyForklift')}
                                  </p>
                                  <p className="text-muted">
                                    {t('header.HeavyForkliftSubtext')}
                                  </p>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li className="relative lg:border-b-0 border-b border-b-slate-200 lg:px-4">
                      <a
                        href={cmsDomain + '/about-us'}
                        target="_blank"
                        className="cursor-pointer block whitespace-nowrap lg:py-0 py-4"
                        onClick={() => toggleMenu()}
                      >
                        {t('header.about_us')}
                      </a>
                    </li>
                    <li className="relative lg:border-b-0 border-b border-b-slate-200 lg:px-4">
                      <a
                        href={cmsDomain + '/special-offer'}
                        target="_blank"
                        className="cursor-pointer block whitespace-nowrap lg:py-0 py-4"
                        onClick={() => toggleMenu()}
                      >
                        {t('header.special_offer')}
                      </a>
                    </li>
                    <li className="relative lg:border-b-0 border-b border-b-slate-200 lg:px-4">
                      <a
                        href={'/'}
                        target="_blank"
                        className="cursor-pointer block whitespace-nowrap lg:py-0 py-4"
                        onClick={() => toggleMenu()}
                      >
                        {t('header.road_safety')}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="lg:px-3.5 xl:px-[22px] order-last lg:order-none py-9 lg:py-0">
                <div className="flex items-center justify-center -mx-3 lg:-mx-2">
                  <div className="px-3 lg:px-2 scale-125 lg:scale-100">
                    <a
                      href="https://www.facebook.com/first-driving-center"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FBIcon />
                    </a>
                  </div>
                  <div className="px-3 lg:px-2 scale-125 lg:scale-100">
                    <a
                      href="https://twitter.com/first-driving-center"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <TwitterIcon />
                    </a>
                  </div>
                  <div className="px-3 lg:px-2 scale-125 lg:scale-100">
                    <a href="" target="_blank" rel="noreferrer">
                      <YoutubeIcon />
                    </a>
                  </div>
                  <div className="px-3 lg:px-2 scale-125 lg:scale-100">
                    <a
                      href="https://www.instagram.com/first-driving-center"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <InstaIcon />
                    </a>
                  </div>
                  <div className="px-3 lg:px-2 scale-125 lg:scale-100">
                    <a
                      href="https://www.linkedin.com/company/first-driving-center"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <LinkedinIcon />
                    </a>
                  </div>
                </div>
              </div>
              <div className="lg:px-3.5 xl:px-[22px] py-3.5 lg:py-0 inline-flex items-center border-b lg:border-0">
                <Link to={cmsDomain + '/find-us'}>
                  <a className="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="currentColor"
                      className="ltr:mr-2 rtl:ml-2"
                    >
                      <path d="M12.6667 21C11.4244 21 10.2558 20.7631 9.16091 20.2894C8.06605 19.8157 7.11348 19.1737 6.30321 18.3635C5.49296 17.5532 4.85098 16.6006 4.37726 15.5058C3.90355 14.4109 3.66669 13.2423 3.66669 12C3.66669 10.7577 3.90355 9.58909 4.37726 8.49422C4.85098 7.39936 5.49296 6.44679 6.30321 5.63652C7.11348 4.82627 8.06605 4.18429 9.16091 3.71057C10.2558 3.23686 11.4244 3 12.6667 3C14.9975 3 17.008 3.76891 18.6984 5.30672C20.3888 6.84454 21.3564 8.74423 21.6013 11.0058H20.5898C20.4141 9.55833 19.8914 8.25928 19.0215 7.10865C18.1516 5.95802 17.0334 5.10898 15.6667 4.56155V5C15.6667 5.55 15.4709 6.02083 15.0792 6.4125C14.6875 6.80417 14.2167 7 13.6667 7H11.6667V9C11.6667 9.28333 11.5709 9.52083 11.3792 9.7125C11.1875 9.90417 10.95 10 10.6667 10H8.66669V12H10.5128V15H9.66669L4.86669 10.2C4.81669 10.5 4.77085 10.8 4.72919 11.1C4.68752 11.4 4.66669 11.7 4.66669 12C4.66669 14.1833 5.43335 16.0583 6.96669 17.625C8.50002 19.1917 10.4 19.9833 12.6667 20V21ZM21.6898 20.7308L18.2398 17.3192C17.941 17.5321 17.6173 17.6987 17.2686 17.8192C16.9199 17.9397 16.5526 18 16.1667 18C15.1859 18 14.3574 17.6619 13.6811 16.9856C13.0048 16.3093 12.6667 15.4808 12.6667 14.5C12.6667 13.5192 13.0048 12.6907 13.6811 12.0144C14.3574 11.3381 15.1859 11 16.1667 11C17.1475 11 17.976 11.3381 18.6523 12.0144C19.3285 12.6907 19.6667 13.5192 19.6667 14.5C19.6667 14.8987 19.6032 15.2756 19.4763 15.6308C19.3494 15.9859 19.1731 16.3128 18.9475 16.6116L22.3975 20.0231L21.6898 20.7308ZM16.1667 17C16.8667 17 17.4584 16.7583 17.9417 16.275C18.425 15.7917 18.6667 15.2 18.6667 14.5C18.6667 13.8 18.425 13.2083 17.9417 12.725C17.4584 12.2417 16.8667 12 16.1667 12C15.4667 12 14.875 12.2417 14.3917 12.725C13.9084 13.2083 13.6667 13.8 13.6667 14.5C13.6667 15.2 13.9084 15.7917 14.3917 16.275C14.875 16.7583 15.4667 17 16.1667 17Z" />
                    </svg>
                    {t('header.find_us')}
                  </a>
                </Link>
                <div className="mx-3 lg:mx-5 border-opacity-50 border-l border-[#8A8C8B]/0.5 h-3"></div>
                <div>
                  <span
                    className="cursor-pointer text-sm"
                    onClick={() => changeLanguage(lang === 'en' ? 'ar' : 'en')}
                  >
                    {lang === 'en' ? 'عربى' : 'English'}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="px-3 lg:hidden" onClick={() => toggleMenu()}>
            <HamburgerIcon />
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderCanvas;
