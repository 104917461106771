import React, { Component, Dispatch } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
// actions
import { actions as authUserActions } from '../../store/reducers/authUser';
import { RouteKeys } from '../route-keys';
// import { thisExpression } from '@babel/types';

interface CustomProps {
  component: any;
  isUserAuthenticated: () => boolean;
  redirectSave: (pathname: string) => void;
  redirectTo?: string;
  exact?: boolean;
  path?: string;
  selectedLanguage?: string;
}

class RedirectIfAuthenticatedRoute extends Component<CustomProps> {
  render() {
    const {
      isUserAuthenticated,
      redirectSave,
      redirectTo,
      component,
      selectedLanguage,
      ...rest
    } = this.props;

    return (
      <Route
        {...rest}
        render={(props) => {
          // console.log('props location: ', props, props.location);
          if (!isUserAuthenticated()) {
            return <this.props.component {...props} />;
          }
          if (isUserAuthenticated()) {
            redirectSave(redirectTo || `${RouteKeys.Home}`);
          }
          return (
            <Redirect
              to={{
                pathname: `${RouteKeys.Home}`,
              }}
              from={props.location.pathname}
            />
          );
        }}
      />
    );
  }
}

// AuthenticateRoute.propTypes = {
//   component: PropTypes.elementType.isRequired,
//   location: PropTypes.shape({
//     pathname: PropTypes.string.isRequired,
//   }).isRequired,
//   isUserAuthenticated: PropTypes.func.isRequired,
//   redirectSave: PropTypes.func,
// };

// AuthenticateRoute.defaultProps = {
//   redirectSave: () => {},
// };

const mapStateToProps = (state: any) => ({
  isUserAuthenticated: () => state.authUser.token != null,
  selectedLanguage: state.language.selectedLanguage,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  redirectSave: (to: string) => {
    dispatch(authUserActions.redirectSave(to));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RedirectIfAuthenticatedRoute);
